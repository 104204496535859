.ChatModal {
    text-align: right;
    background: rgba(237, 237, 237, 0.5);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .NewHighlightText {
        margin: 0;
        background-color: rgba(#339704, 0.25);
        border: 1px solid #CACACA;
        padding: 16px 16px 12px 16px;
        font-family: Tajawal;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: right;
    }
    &Buttons {
        background: var(--white);
        padding: 1.714em;
        font-family: Cairo;
        font-size: 1em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.714em;
        text-align: left;   
        padding:0;
        border-left: 1px solid #E8E8E8;
        padding:20px 0 30px 0;
        .colored {
            width: 13.929em;
            height: 2.857em;
            font-size: 1em;
            margin-left: 1.714em;
            background: var(--white);
            color: var(--blueColor);
            border: 1px solid var(--blueColor);
        }
        .colorless {
            width: 6.286em;
            height: 2.857em;
            font-size: 1em;
        }
    }
    .CommentHandled {
        float: right;
        padding-top: 40px;
        background: var(--white);
        display: flex;
        padding-right: 1em;
        padding-top: 1.188em;
        font-family: Cairo;
        font-size: var(--px16);
        font-weight: 700;
        line-height: 1.5em;
        color: var(--secondaryHeader);
        margin-right: 10px;
        // border-bottom: 1px solid var(--borderGray);
        &Checked {
            background-color: #626262 !important;
            border: 2px solid #626262 !important;
        }
    }
    .MessageInput {
        background: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.857em 2.286em 2.500em 2.857em ;
        .Input {
            margin-bottom: 0;
        }
        input {
            height: 2.500em;
            width: 37.688em;
            border-radius: 1.250em;
            font-family: Tajawal;
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            color: var(--secondaryHeader);
            border-color: var(--blueColor);
        }
        button {
            width: 2.857em;
            height: 2.857em;
            border-radius: 50%;
            border: 0px;
            background-color: transparent;
            outline: none;
        }
        img {
            border-radius: 50%;
            object-fit: cover;
            width: 40px;
            height: 40px;
        }
    }
    .ChatWrapper {
        background: rgba(237, 237, 237, 0.6);
        height: 352px;
        overflow-y: scroll;
        margin-left: 1.500em;
        direction: rtl;
        .Chat {
            height: 16.571em;
            display: flex;
            flex-direction: column;
            padding-top: 0.857em;
            padding-bottom: 0.857em;
            .MessageWriter {
                background-color: var(--blueColor);
                width: 19.429em;
                border-radius: 4px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
                margin-bottom: 0.571em;
                margin-right: 1.714em;
                .Author {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.667em;
                    color: var(--white);
                    font-family: Cairo;
                    font-size: var(--px12);
                    font-weight: 600;
                    line-height: 2em;
                    border-bottom: 1px solid rgba(237,237,237, 0.25);
                    span {
                        font-weight: 400;
                        color: var(--grayColorMain);
                    }
                    img {
                        border-radius: 50%;
                        margin-left: 0.571em;
                        width: 2em;
                        height: 2em;
                        object-fit: cover;
                    }
                }
                .comment {
                    font-family: Tajawal;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5em;
                    text-align: right;
                    color: var(--white);
                    padding: 0.571em;
                }
            }
            .MessageEditor {
                width: 31.429em;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                margin-bottom: 0.571em;
                margin-left: 1.714em;
                align-self: flex-end;
                .Editor {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.667em;
                    color: var(--black);
                    font-family: Cairo;
                    font-size: var(--px12);
                    font-weight: 700;
                    line-height: 2em;
                    border-bottom: 1px solid rgba(237,237,237, 1);
                    span {
                        font-weight: 400;
                        color: var(--grayColorMain);
                    }
                    img {
                        margin-right: 0.571em;
                        width: 2em;
                        height: 2em;
                        object-fit: cover;
                    }
                }
                .comment {
                    font-family: Tajawal;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5em;
                    text-align: right;
                    color: var(--secondaryHeader);
                    padding: 0.571em;
                }
            }
        }
    }::-webkit-scrollbar {
        width: 0.571em;
    }::-webkit-scrollbar-thumb {
        background-color: var(--grayColorMain);
        opacity: 0.5;
        border-radius: 0.714em;
    }::-moz-webkit-scrollbar {
        width: 0.571em;
    }::-moz-webkit-scrollbar-thumb {
        background-color: var(--grayColorMain);
        opacity: 0.5;
        border-radius: 0.714em;
    }
    &Fixed {
        margin: 0 16px 24px 16px;
        background-color: var(--lightGrey);
        padding: 16px 16px 12px 16px;
        border: 1px solid #CACACA;
        font-family: Tajawal;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: right;
    }
    &Title {
        background-color: white;
        padding: 16px;
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: right;
    }
    &Header {
        background: var(--white);
        display: flex;
        justify-content: space-between;
        .Comments {
            font-family: Cairo;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 700;
            line-height: 1.5em;
            img {
                margin-left: 0.556em;
            }
        }
        .RemainigTime {
            font-weight: 700;
            line-height: 1.714em;
            color: var(--secondaryHeader);
        }
        padding: 2.143em 1.714em 1.714em 4.571em;
        span {
            color: var(--alert);
        }
    }
    &SubHeader {
        background: rgba(255, 51, 102, 0.25);
        border-top: 1px solid var(--borderGray);
        padding: 1.125em 1.500em 0.688em 1.500em;
        font-family: Tajawal;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        border-left: 1px solid #E8E8E8;
        margin-right: 25px;
    }
    &SubHeaderTitle {
        background: rgb(255, 255, 255);
        // border-top: 1px solid var(--borderGray);
        padding: 1.125em 1.500em 0.688em 1.500em;
        font-family: Tajawal;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        color:#CACACA;
        border-left: 1px solid #E8E8E8;
        padding-left: 10px;
        img{
            margin-left:10px;
        }
    }
    &SubHeaderChanges {
        background:rgba(232, 142, 9, 0.25);      
    }
    &RightColumn {
        float: right;
        background: white;
        width: 50%;
        display: block;
        height: 100%;
        min-height: 500px;
        padding-left: 35px;
    }
    &EditorColumn{
        background: white;
        display: block;
        padding-bottom: 20px;
        width: 100%;
    }
    &LeftColumn {
        float: left;
        background: white;
        width: 50%;
        display: block;
        flex: 50%;
        // height: 100%;
        // min-height: 500px;
    }
}
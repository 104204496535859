.DragAndDropImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .drag-drop-zone {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: Tajawal;
    font-weight: 500;
    line-height: 1.714em;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 9.222em;
    img {
      border-radius: 5px;
    }
    & .dropped-files {
      font-weight: bold;
    }
    .DnDPlaceholder {
      top: 30px;
      position: absolute;
      display: flex;
      flex-direction: column;
      font-family: Tajawal;
      font-size: 1em;
      font-style: normal;
      font-weight: 500;
      line-height: 1.714em;
      text-align: center;
      align-items: center;
      justify-content: center;
      input[type="file"] {
        display: none;
      }
      .inputFileUpload {
        margin-top: 1.143em;
        background-color: var(--white);
        border: 1px solid var(--blueColor);
        border-radius: 4px;
        width: 9.286em;
        height: 2.714em;
        cursor: pointer;
        font-family: Cairo;
        font-style: normal;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1em;
        color: var(--blueColor);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 2.286em;
        height: 2.286em;
        margin-bottom: 0.786em;
      }
    }
  }
  
  .drag-drop-zone.inside-drag-area {
    opacity: 0.7;
  }
  
.UserProfileNotificationsItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderGray);
    padding-bottom: 2.286em;
    &Type {
        background-color: var(--lightGrey);
        width: 4.571em;
        height: 4.571em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-left: 2.286em;
        img {
            width: 1.429em;
            height: 1.429em;
            align-self: center;
        }
    }
    &Content {
        font-family: Cairo;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.714em;
        width: 38.429em;
        .Flex {
            button { 
                margin-left: var(--px16);
            }
            .RemainingTime {
                font-weight: 700;
                align-self: center;
            }
        }
        button {
            width: 9.286em;
            height: 2.714em;
            font-size: 1em;
            line-height: 1em;
        }
        .iconPhoto40 {
            margin-left: 0.286em;
        }
        .TextNote {
            width: 33.625em;
            background-color: #FFF3CF;
            padding: 1.500em;
            font-family: Tajawal;
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;
        }
        .iconPhoto40 {
            width: 2.286em;
            height: 2.286em;
        }
        .Name {
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
        }
        .Something {
            font-size: var(--px16);
            font-weight: 600;
            line-height: 1.5em;
        }
        .Text {
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.813em;
            color: var(--secondaryHeader);
        }
    }
    .iconNotificationsPicture{ 
        margin-right: 32px;
        width: 4.571em;
        height: 4.571em;
        img {
            width: 4.571em;
            height: 4.571em;
            border-radius: 4px;
           
            object-fit: cover;
        }
    }
}
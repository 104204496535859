.login {
    display: flex;
    justify-content: flex-end;
    button {
        outline: none;
    }
    &Content {
        width: 29.286em;
        text-align: right;
        margin-top: 12.6em;
        margin-right: 16.429em;
        margin-left: 6.8em;
        line-height: 1.5em;
        .btnContent {
            img {
                margin-left: 0.571em;
            }
        }
        .forgetPassword {
            cursor: pointer;
            display: flex;
            color: var(--blueColor);
            font-weight: 600;
        }
        &Btn {
            width: 100%;
            font-size: 1em;
        }
        &Line {
            display: flex;
            text-align: center;
            div {
                display: table-cell;
                margin-top: auto;
                margin-bottom: auto;
                font-family: Cairo;
                font-size: var(--px18);
                font-style: normal;
                font-weight: 600;
                line-height: 1.5em;
                color: #505050;
            }
            hr {
                width: 100%;
                border-top: 1px solid var(--grayColor);
            }
        }
        &Inputs {
            .Input {
                label {
                    font-weight: 600;
                    line-height: 1.5em;
                    margin-bottom: 0.571em;
                    img {
                        margin-right: 0.2em;
                        margin-bottom: 0.7em;
                    }
                }
                input {
                    background-color: var(--lightGrey);
                    border-color: #EDEDED;
                }::placeholder {
                    padding-right: 1em;
                    color: #A0A0A0;
                }
            }
            
        }
        &Text {
            line-height: 1.5em;
            margin-top: 20px;
            .subText {
                font-size: var(--px18);
                font-weight: 600;
                line-height: 1.874em;
            }
            .textTitle {
                font-size: var(--px18);
                font-weight: 700;
                &Secondary {
                    font-size: 1.142em;
                    font-weight: 600;
                    color: var(--secondaryHeader);
                    span {
                        color: var(--blueColor);
                        cursor: pointer;
                    }
                }
            }
        }
        &Logos {
            display: flex;
            .Logo {
                margin-left: 0.321em;
                img{
                    width: 2.5em;
                    height: 2.5em;
                }
               
            }
        }
    }
    &Picture {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        width: 50%;
        height: 100vh;
        background:  linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(../../assets/images/signup-login/login.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        
        &Content {
            padding-top: 50%;
            .tableCell {
                display: flex;
                margin-right: 7.35em;
                .icon {
                    width: 40px;
                    margin-top: 0.429em;
                    img {
                        opacity: 1
                    }
                }
                
                .text {
                    color: var(--white);
                    font-weight: 700;
                    font-size: 1.429em;
                    line-height: 1.874em;
                    width: 23em;
                    text-align: right;
                }
            }
        }
    }
}
.googleButton {
    background-color: var(--white);
    width: 100%;
    height: 4em;
    font-weight: 700;
    border-radius: 4px;
    color: #505050;
    border: 1px solid #EDEDED;
}
.facebookButton {
    width: 100%;
    height: 4em;
    font-weight: 700;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    color:  var(--white);
    background-color: #3B5998;
    img {
        margin-left: 0.571em;
    }
    
}

@media screen and (max-width: 767px){
    .login {
        overflow-x: hidden;
        flex-direction: column;
        margin-right: 1.071em;
        margin-top: 2.286em;
        margin-left: 1.071em;
        &Content {
            width: 100%;
            margin-left: 1em;
            margin-right:0;
            margin-top: 0;
            &Inputs {
                .inlineFlex {
                    display: block;
                    .Input {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        }
        &Picture {
            width: 100%;
            display: none;
        }
    }
}

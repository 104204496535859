.Modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5000;
    background-color: var(--modalBg);
    overflow-y: auto;
    &Bg {
        min-height: 100vh;
        height: 100%;
    }
    &Body {
        border-radius: 4px;
        position: relative;
        top: 10%;
        width: 790px;
        margin: 0 auto;
        padding: 32px 30px 22px 30px;
        background-color: var(--mainBgColor);
       
    }
    &Close {
        position: absolute;
        top: 29px;
        left: 29px;
        z-index: 5001;
        cursor: pointer;
    }
}
.ArticleReview {
    .textToSelectEditor {
        user-select: text;
        .HighlitedText {
            background-color: rgba(#339704, 0.25 ) !important;
            position: relative;
            user-select: none;
        }
        ::selection {
            background: rgba(#339704, 0.25 ) !important;
        }
    } 
}
// .Hello {
//     input[type='checkbox'] {
//         border: 2px solid transparent !important;
//         &:checked {
//           --b: var(--active);
//           --bc: var(--active);
//           --d-o: .3s;
//           --d-t: .6s;
//           --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
//           background-color: rgba(98, 98, 98, 0.25) !important;
//         }
//     }
// }
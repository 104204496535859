.UserProfileGoBack {
    border-bottom: 1px solid #CACACA;
    padding-bottom: 0.438em;
    font-family: Cairo;
    font-size: var(--px16);
    font-style: normal;
    font-weight: 600;
    line-height: 1.5em;
    text-align: right;
    cursor: pointer;
    img {
        margin-left: 0.250em;
        width: 1em;
        height: 1em;
    }
}
.UserProfileCV {
    button {
        width: 9.714em;
        height: 2.857em;
        font-size: 1em;
    }
    &Item {
        font-size: 1em;
        line-height: 1.5em;
        border-bottom: 1px solid var(--grayColorMain);
        display: flex;
        &Flex {
            padding-bottom: 32px;
            width: 200px;
            margin-right: 15px;
            &Title {
                font-weight: 700;
            }
            &Text {
                font-weight: 400;
            }
        }
    }
}
.UserProfileEngagements {
    display: inline-flex;
    background-color: var(--lightGrey);
    height: 2.500em;
    max-width: 16.313em;
    min-width: 8.313em;
    padding: 8px 11px 8px 8px;
    font-family: Cairo;
    font-size: var(--px16);
    font-weight: 600;
    line-height: 1.5em;
    text-align: right;
    color: var(--secondaryHeader);
    justify-content: space-between;
    // .shared {
    //     margin-right: 0.625em;
    // }
    div {
        align-self: center;
    }
}
.UserProfileSmallInfo {
    position: relative;
    display: flex;
    .Badges {
        position: absolute;
        left: 3%;
        top: -33px;
        img {
            margin-right: 0.571em;
            width: 2.286em;
            height: 4.357em;
            object-fit: cover;
        }
    }
    &Photo {
        img {
            border-radius: 50%;
            width: 10em;
            height: 10em;
            object-fit: cover;
        }
    }
    &Content {
        margin-right: 1.357em;
        &Title {
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.455em;
            img {
                margin-right: 0.170em;
                margin-left: 0.534em;
            }
            button {
                width: 8.333em;
                height: 2em;
                font-size: 12px;
                line-height: 1.167em;
            }
        }
        &Text {
            font-family: Tajawal;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            color: var(--secondaryHeader);
        }
    }
    
}

.interestsTabsHeader{
    display: flex;
    justify-content: space-between;
    h1 {
        font-family: Cairo;
        font-size: 1.714em;
        font-weight: 700;
        line-height: 1.750em;
        text-align: right;
        margin-bottom: 0;
    }
    img {
        margin-left: 1.5em;
        margin-top: 0.7em;
        width: 25px;
        height: 25px;
    }
    &Numbers {
        display: flex;
        font-family: Cairo;
        font-size: 1em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.714em;
        text-align: right;
        color: var(--secondaryHeader);
        margin-top: 0.714em;
        span {
            margin-left: 0.571em;
            font-weight: 700;
        }
        .border {
            margin-top: 0.25em;
            height: var(--px16);
            margin-left: var(--px16);
            margin-right: var(--px16);
        }

    }
}
.interestsTabsMenu {
&Content {
    font-size: 1em;
    font-weight: 700;
    line-height: 2.429em;
    text-align: right;
    border-bottom: 1px solid var(--borderGray);
    width: 18.214em;
    div {
        display: flex;
        img {
            align-self: center;
            width: 1.714em;
            height: 1.714em;
            margin-left: var(--px18);
        }
    }
}
button {
    font-size: 1em;
    line-height: 1em;
    width: 18.214em;
}
}

@media screen and (max-width: 767px){
    .UserProfileCV {
        &Item {
            &Flex {
                margin-right: 0;
                width: 11em;
            }
        }
        
    }
    .UserProfileSmallInfo {
        flex-direction: column;
        
    }
    .searchPageMenu {
        &Item {
            padding: 0.571em 0.571em;
        }
    }
    .UserProfileSmallInfoContent {
        .WritersSearchNumbers {
            .displayNone {
                display: none;
            }
            .spanBold {
                margin-right: 0.714em;
            }
        }
    }
    
}
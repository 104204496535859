.Article {
    margin-top:4.571em;
    .imageContainer {
        object-fit: cover;
        width: 100%;
        // height: 380px;
        height: auto;
    }
    .OldestVersion {
        background-color: var(--lightGrey);
        
        &Title {
            height: 96px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: Cairo;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;
            button {
                width: 124px;
                height: 38px;
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: center;
            }
        }
    }
    .videoWrapper {
        margin-bottom: 32px;
        width: 100%;
        height: 500px;
    }
    overflow-x: hidden;
    font-family: Cairo;
    text-align: right;
    user-select: none;
    .HighlitedText {
        background-color: #D7EBFF;
        position: relative;
        user-select: none;
    }
    .iconMore {
        position: relative;
        .PopUpMore {
            display: none;
            position: absolute;
            z-index: 25;
            top: 60%;
            left: 0;
            background-color: var(--white);
            border: 1px solid var(--lightGrey);
            border-radius: 4px;
            width: 13.786em;
            //height: 8.000em;
            font-family: Cairo;
            text-align: center;
            padding: 0.857em 0.714em;
            div {
                font-weight: 700;
                cursor: pointer;
                display: flex;
                color: var(--black) !important;
                line-height: 2.429em;
            }
            img {
                margin-left: 1.286em;
                align-self: center;
                width: 1.429em;
                height: 1.429em;
            }
        }
    }
    .iconShare {
        position: relative;
        .PopupShare {
            display: none;
            position: absolute;
            z-index: 25;
            top: 60%;
            left: 0;
            background-color: var(--white);
            border: 1px solid var(--lightGrey);
            border-radius: 4px;
            width: 13.571em;            
            height: 9.229em;
            font-family: Cairo;
            text-align: center;
            padding: 0.857em 0.714em;
            div {
                font-weight: 700;
                cursor: pointer;
                display: flex;
                color: var(--black) !important;
                line-height: 2.429em;
            }
            img {
                margin-left: 1.286em;
                align-self: center;
                width: 1.429em;
                height: 1.429em;
            }
        }
    } 
    
    &Menu {
        margin-top: 5.143em;
        text-align: center;        
        margin-right: 100px;
        
        div {
            cursor: pointer;
            font-size: 1em;
            font-weight: 600;
            line-height: 1.5em;
            color: var(--secondaryHeader);
            img {
                width: 2.286em;
                height: 2.286em;
            }
        }
        .VerifiedArticle {
            display: inline-block;
            vertical-align: top;
            color: var(--success);
            font-family: Cairo;
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            border-bottom: 1px solid var(--grayColorMain);
            padding-bottom: 0.5em;
            img {
                margin-right: 0.5em;
                width: 1em;
                height: 1em;
            }
        }
    }
    &Content {
        user-select: none;
        -webkit-user-select: none;
        margin-top: 5.143em;
        .DifferentArticles {
            user-select: none;
            -webkit-user-select: none;
        }
        &UserProfile {
            user-select: none;
            -webkit-user-select: none;
            display: flex;
            .iconPhoto100 {
                cursor: pointer;
                img {
                    border-radius: 50%;
                    object-fit: cover;
                    width: 5.143em;
                    height: 5.143em;
                    margin-left: var(--px16);
                }
            }
            .UserProfileContent {
                .Numbers {
                    display: flex;
                    div {
                        margin-left: 1.714em;
                    }
                    .text {
                        font-weight: 700;
                        span {
                            color: var(--secondaryHeader);
                        }
                    }
                    .spans {
                        font-weight: 400;
                        line-height: 1.5em;
                        color: var(--secondaryHeader);
                        span {
                            font-weight: 700;
                        }
                    }
                }
                &Title {
                    display: flex;
                    align-items: center;
                    font-size: var(--px16);
                    font-weight: 700;
                    line-height: 1.5em;
                    button {
                        width: 5.571em;
                        height: 1.714em;
                        margin-right: 0.857em;
                    }
                    img {
                        margin-right: 0.286em;
                    }
                }
                
            }
        }
        &Viewers {
            user-select: none;
            -webkit-user-select: none;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            color: var(--secondaryHeader);
            border-bottom: 1px solid var(--grayColorMain);
            padding: 0.714em 0;
            .iconGrayCircle {
                margin-left: 1.214em;
                margin-right: 1.214em;
                img {
                    width: 0.571em;
                    height: 0.571em;
                }
            }
        }
        &Likes {
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
            margin-top: 4.571em;
            margin-left: auto;
            margin-right: auto;
            width: 7.143em;
            height: 7.143em;
            background-color: var(--lightGrey);
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            text-align: center;
            div {
                font-family: Cairo;
                font-size: 1em;
                font-weight: 700;
                line-height: 1.5em;
                color: var(--secondaryHeader);
            }
            img {
                margin-top: 20%;
                width: 2.500em;
                height: 2.500em;
            }
        }
        &Interests {
            display: flex;
            flex-wrap: wrap;
            user-select: none;
            -webkit-user-select: none;
            div {
                margin-bottom: 8px;
                margin-left: var(--px16);
                font-weight: 700;
                line-height: 1.5em;
                background-color: #F6F6F6;
                border-radius: 0.286em;
                padding: 0.571em;
            }
        }
        &Title {
            margin-top: 1em;
            font-family: Tajawal;
            font-size: 2.286em;
            font-weight: 700;
            line-height: 1.5em;
            border-right: 0.250em solid var(--blueColor);
            padding-right: 1.781em;
        }
        .NumberedList {
            .iconBlackCircle {
                width: 0.444em;
                height: 0.444em;
                margin-left: 0.444em;
                background-color: black;
                border-radius: 50%;
                display: flex;
            }
            img {
                margin-left: 0.444em;
            }
            &Numeric {
                font-weight: 700;
                margin-left: 0.444em;
            }
            margin-top: 1.778em;
            font-family: Tajawal;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
        }
        &Span {
            position: relative;
            background-color: var(--myHighlight);
            font-family: Tajawal;
            font-size: var(--px18);
            font-weight: 500;
            line-height: 1.5em;
        }
        h1 {
            margin-top: 1em;
            font-family: Cairo;
            font-size: 2.286em;
            font-weight: 700;
            line-height: 1.500em;
        }
        h2 {
            font-family: Cairo;
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.5em;
        }
        &Header {
            cursor: pointer;
            font-size: var(--px16);
            font-weight: 600;
            line-height: 1.5em;
            color: var(--secondaryHeader);
            border-bottom: 1px solid var(--grayColorMain);
            padding-bottom: 0.5em;
            img {
                width: 1em;
                height: 1em;
            }
        }
        &Text {
            font-family: Tajawal;
            //font-size: 1.429em;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            color: var(--secondaryHeader);
            
        }
        &Paragraph {
            font-family: Tajawal;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
        }
        &Picture {
            user-select: none;
            -webkit-user-select: none;
            margin-top: 2.857em;
            color: var(--blueColor);
            font-weight: 600;
            line-height: 1.714em;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &Iframe {
            user-select: none;
            -webkit-user-select: none;
            margin-top: 2.286em;
        }
        .AuthorData {
            user-select: none;
            -webkit-user-select: none;
            display: flex;
            justify-content: space-between;
            margin-top: 0.750em;
            justify-content: space-between;
            align-items: center;
            font-family: Cairo;
            font-size: var(--px16);
            font-weight: 400;
            line-height: 1.5em;
            color: var(--secondaryHeader);
            .ReviewerTitle{
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                color: var(--black);
            }
            .spanBold {
                font-weight: 600;
            }
            .authorPhotoSpan {
                font-weight: 700;
                color: var(--black);
                cursor: pointer;
                direction: rtl;
            }
            .authorPhoto {
                display: flex;
                align-items: center;
                .ArticlePhotoImage {
                    border-radius: 50%;
                        width: 2.500em;
                        height: 2.500em;
                        object-fit: cover;
                   
                }
                button {
                    font-size: 0.750em;
                    line-height: 1.167em;
                    width: 6.500em;
                    height: 2em;
                }
            }
            img {
                margin-left: 0.750em;
                margin-right: 0.750em;
            }
        }
    }

    .ArticleContentHover {
        position: relative;
    }
    .ArticleContentHoverText {
        padding: 1.250em;
        height: 4.000em;
        right: 45%;
        top: -4.7em;
        background-color:var(--black);
        color: var(--white);
        text-align: right;
        visibility: hidden;
        padding: var(--px16);
        border-radius: 4px;
        position: absolute;
        z-index: 40;
    }
    .ArticleContentHover:hover {
        .ArticleContentHoverText {
            visibility: visible;
            display: block;
        }
    }
   

    .ArticleContentHover:hover::before {
        content: ''; 
        position: absolute;
        right: 50%;
        top: -0.7em;
        border: 9.6px solid transparent;
        z-index: 2;
        border-top: 9.6px solid var(--black);
      }

    

    .ArticleNumberHover {
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        color: var(--blueColor);
    }
    
      
     .ArticleNumberHoverText {
        font-family: Tajawal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5em;
        display: none;
        width: 21.500em;
        height: 8.286em;
        background-color:var(--black);
        color: var(--white);
        text-align: right;
        padding: var(--px16);
        bottom: 140%;
        right: -1000%;
        border-radius: 4px;
        position: absolute;
        z-index: 40;
      }
      
      .ArticleNumberHover:hover{
        .ArticleNumberHoverText {
        visibility: visible;
        display: block;
      }
    
      } 
      .ArticleNumberHover:hover::before {
        content: ''; 
        position: absolute;
        right: 4px; top: -11px;
        border: 9.6px solid transparent;
        z-index: 2;
        border-top: 9.6px solid var(--black);
      }
}::selection {
    background-color: var(--selectionColor);
} 

.PortalHighlight {
    position: relative;
    .text {
        color: white;
        font-family: Cairo;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        margin-left: 1em;
    }
    .highlightBorder {
        margin-left: var(--px16);
        width: 0.143em;
        height: 1.714em;
        background-color: var(--secondaryHeader);
    }
}
.PortalHighlight:after {
    content: ''; 
    position: absolute;
    left: 7.214em; 
    bottom: -1.786em; 
    border: 0.686em solid transparent; 
    border-top: 0.686em solid var(--black); 
}

.textToSelect {
    user-select: text;
}
.CommentDiv {
    font-weight: 700;
    user-select: none;
    cursor: pointer;
}
.CommentDivFixed {
    div {
        color: var(--secondaryHeader) !important;
    }
}

@media screen and (max-width: 867px){
    .ArticleMenu {
        display: none;
    }       
}
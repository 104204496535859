.TrendingMawsuah {
    height: 25.15em;
    overflow: hidden;
    &Photo {
        //width: 52.1em;
        //overflow: hidden;
        width: 730px;
        img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        }
        padding-right: 0 !important;
    }
    &Content {
        width: 379px;
        padding-right: 1.714em;
        background-color: var(--black);
        text-align: right;
        .contentTitle{
            font-size: 1.429em;
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;
            color: var(--white);
            font-family: Cairo;
        }
        .contentText {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            color: var(--white);
            font-family: Cairo;
            font-size: 1.714em;
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;
            margin-left:  1.5em;
        }
    }
    .moreArticles {
        text-align: right;
        color: var(--white);
        display: flex;
        div {
            font-family: Cairo;
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            margin-left: 0.5em;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .Flex {
        color: var(--white);
        .iconPhoto {
            margin-left: 0.714em;
            
            img {
                width: 50px;
                height: 50px;
            }
        }
        .item1 {
            margin-left: 0.554em;
            font-family: Cairo;
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;

        }
        .item2 {
            font-family: Cairo;
            font-size: 0.929em;
            font-style: normal;
            font-weight: 700;
            line-height: 1.538em;
            text-align: right;

        }
    }
}
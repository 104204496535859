.SuggestedArticles {
    text-align: right;
    .moreSuggestedArticles {
        float: left;
        color: var(--blueColor);
        display: flex;
        cursor: pointer;
        div {
            margin-left: 0.5em;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
        }
    }
    &List {
        .whyMawsuah{
            width: 18.2em;
            height: 19.78em;
            background-color: var(--black);
            text-align: right;
            &Title {
                font-size: 1.286em;
                font-weight: 700;
                line-height: 1.8em;
                color: var(--white);
                margin-right: 1.71rem;
                margin-left: 1.9rem;
                padding-top: 1.7777em;
            }
            &Text {
                height: 9.000em;
                font-family: var(--secondaryFontFamily);
                font-size: 1.143em;
                font-weight: 400;
                line-height: 1.5em;
                color: var(--white);
                margin-right: 1.71rem;
                margin-left: 1.9rem;
            }
            &Btn {
                text-align: center;
                .registerBtn{
                    font-size: 1em;
                    font-weight: 700;
                    line-height: 1em;
                    width: 14.28em;
                    height: 3.42em;
                }
            }
        }
        &MostReadInterests {
            width: 18.2em;
            .MostReadInterestsTitle {
                width: 14.16666666em;
                font-size: 1.286em;
                font-weight: 700;
                line-height: 1.8em;
                cursor: auto;
            }
            .MostReadInterestsLists {
                font-size: 1.143em;
                font-weight: 700;
                line-height: 2.15em;    
                div {
                    cursor: pointer;
                }
            }
            .MostFollowedWritersLists {
                font-size: 1em;
                font-weight: 700;
                line-height: 1.714em; 
                .item1 {
                    margin-left: 0.327em;
                } 
            }
            .iconPhoto {
                //display: table-cell;
                width: 32px;
                height: 32px;
                margin-bottom: auto;
                margin-top: auto;
                margin-left: 0.571em;
                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &Icons {
        cursor: pointer;
        border-radius: 4px;
        width: 11.429em;
        height: 11.429em;
        object-fit: cover;
        display: flex;
    }
    &Photo {
        display: flex;
        justify-content: space-between;
        div { 
            margin-top: auto;
            margin-bottom: auto;
            .suggestedArticlePhoto32 {
                margin-left: 0.617em;
                width: 2.286em;
                height: 2.286em;
                border-radius: 50%;
                object-fit: cover;
            }
            span {
                
                font-weight: 700;
            }
        }
        .blurText {
            filter: blur(8px);
            -webkit-filter: blur(8px);
            color: transparent;
            text-shadow: 0 0 5px rgba(0,0,0,0.5);
        }

        .authorPhoto {
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .authorPhoto .authorPhotoPopup {
              &AuthorPhoto {
                   
                      width: 72px;
                      height: 72px;
                      object-fit: cover;
                      border-radius: 50%;
                  
              }
            &Content {
                margin-right: var(--px16);
                button {
                    font-family: Cairo;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1em;
                    letter-spacing: 0px;
                    text-align: center;
                    width: 9.286em;
                    height: 2.714em;
                }
                &Title{
                    color: var(--black);
                    font-family: Cairo;
                    font-size: var(--px18);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.333em;
                    text-align: right;
                    img {
                        margin-right: 0.554em;
                    }
                }
                &SubTitle{
                    color: var(--secondaryHeader);
                    font-family: Cairo;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.714em;
                    text-align: right;
                    span {
                        font-weight: 600;
                    }
                }
            }
            font-family: Tajawal;
            font-size: 1em;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            visibility: hidden;
            width: 22.357em;
            height: 13.857em;
            color: var(--black);
            background-color:var(--white);
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            color: var(--white);
            text-align: right;
            padding: var(--px16);
            top: 1.786em;
            position: absolute;
            z-index: 2;
            .iconPhoto72 {
                width: 72px;
                height: 72px;
                margin-right: 24px;
            }
          }
          
          .authorPhoto:hover .authorPhotoPopup {
            visibility: visible;
          }

          .article {
              display: flex;
              justify-content: center;
              align-items: center;
          }
          //========================
          .article {
            position: relative;
            display: flex;
          }
          
          .article .articlePopup {
            &Content {
                margin-right: var(--px16);
                button {
                    font-family: Cairo;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1em;
                    letter-spacing: 0px;
                    text-align: center;
                    width: 9.286em;
                    height: 2.714em;
                }
                &Title{
                    color: var(--black);
                    font-family: Cairo;
                    font-size: var(--px18);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.333em;
                    text-align: right;
                    img {
                        margin-right: 0.554em;
                    }
                }
                &SubTitle{
                    color: var(--secondaryHeader);
                    font-family: Cairo;
                    font-size: 1em;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.714em;
                    text-align: right;
                    span {
                        font-weight: 600;
                    }
                }
            }
            visibility: hidden;
            width: 26.286em;
            height: 11.643em;
            background-color:var(--white);
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            color: var(--white);
            text-align: right;
            padding: var(--px16);
            top: 1.586em;
            position: absolute;
            z-index: 2;
            .iconPhoto72 {
                width: 75px;
                height: 75px;
                margin-right: 24px;
                object-fit: cover;
            }
          }
          
          .article:hover .articlePopup {
            visibility: visible;
          }
          
    }
    &Item {
        display:flex;
        margin-bottom: 2.286em;
        padding-bottom: 2.286em;
        border-bottom: 1px solid var(--borderGray);
        &Content {
            width: 38.4em;
            margin-left: 2.214em;
        }
        &Title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            cursor: pointer;
            font-size: var(--px18);
            font-weight: 700;
            line-height: 1.8em;
            text-align: right;
            img { 
                margin-right: 4px;
                margin-bottom: 4px;
            }
        }
        &Text {
            cursor: pointer;
            font-family: var(--secondaryFontFamily);
            font-size: var(--px16);
            font-weight: 500;
            line-height: 1.5em;
            text-align: right;
            color: var(--secondaryHeader);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    &Icon {
        width: 38.5em;
        height: 25em;
        margin-bottom: 1em;       
        background-color: var(--gray);
    }
    h1 {
        margin-left: 4em;
        text-align: right;
        font-size: var(--px18);
        font-weight: 700;
    }
    h2 {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 6em;
        text-align: right;
        font-size: var(--px16);
        font-weight: 500;
        color: var(--secondaryHeader);
    }
}
@media screen and (max-width: 767px){
    .SuggestedArticles {
        &Item {
            &Content {
                margin-left: 0;
            }
        }
        &Icons {
            width: 5.357em;
            height: 5.357em;
            object-fit: cover;
        }
        &Photo {
            display: block;
            .article {
                width: 130%;
                margin-top: 0.857em;
            }
        }
        &List {
            .whyMawsuah {
                margin-top: 2.286em;
                width: 100%;
                height: 17.071em;
                border-radius: 4px;
                &Btn {
                    margin-top: 1em;
                }
            }
            &MostReadInterests {
                width: 100%;
                .MostReadInterestsTitle {
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px){
    .SuggestedArticlesItem {
        .SuggestedArticlesPhoto {
            display: block;
        }
    }
}
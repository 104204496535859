.UserProfileAuthInfo {
    display: flex;
    padding-top: 2.286em;
    position: relative;
    &Photo {
        img {
            width: 10em;
            height: 10em;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &Content {
        margin-right: 1.357em;
        &Title {
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.455em;
            img {
                margin-right: 0.170em;
                margin-left: 0.534em;
            }
            button {
                width: 8.333em;
                height: 2em;
                font-size: 12px;
                line-height: 1.167em;
            }
        }
        &Text {
            font-family: Tajawal;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            color: var(--secondaryHeader);
        }
    }
    .Badges {
        position: absolute;
        left: 3%;
        top: 0;
        img {
            object-fit: cover;
            width: 32px;
            height: 61px;
            margin-right: 0.571em;
        }
    }
}

@media screen and (max-width: 767px){
    .UserProfileAuthInfo {
        flex-direction: column;
        .displayNone {
            display: none;
        }
    }
}
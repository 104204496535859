.telescopeBanner {
    background-color: var(--grayColor);
    width: 100%;
    .container {
        height: 100%;
        .bannerContent {
            margin-top: 8.3em;
            
            .header1{
                font-size: 42px;
                line-height: 63px;
                font-weight: 700;
                text-align: right;
                width: 10.690em;
            }
            .header2{
            text-align: right;
            font-size: 32px;
            line-height: 1.5em;
            font-weight: 600;
            width: 20.344em;
            margin-bottom: 0;
                span{ 
                    height: 3em;
                    color: var(--secondaryHeader);
                }
            
            }
            button {
                float: right;
                margin-bottom: 95px;
            }
        }
    }
}

@media screen and (max-width: 767px){
    .telescopeBanner {
        .container{
            
            .iconTelescope {
                width: 100%;
                height: 100%;
            }
            .bannerContent {
                margin-top: 2.286em;
                button {
                    width: 100%;
                }
                .header1 {
                    width: 100%;
                    font-size: 1.429em;
                }
                .header2 {
                    width: 100%;
                    font-size: 1.143em;
                }
            }
            .telescopeBannerDirection {
                flex-direction: column-reverse;
            }
            button {
                margin-bottom: 2.286em;
            }
        }
       
    }
}

.ArticleParagraph {
    position: relative;
    font-family: Tajawal;
    font-size: var(--px18);
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 32px;
    color: var(--black);
}
.ArticleNote{
    direction: rtl;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 4px;
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0;
    text-align: right;
    background-color: #FFF3CF;
    overflow: hidden;
    text-indent: 30px;
    background-image: url(../../assets/images/Articles/articleNote.svg);
    background-repeat: no-repeat;
    background-position: 96% 17%;
    background-size: 26px;
}
blockquote {
    border-right: 0.250em solid var(--blueColor);
    font-family: Tajawal;
    font-size: 2.286em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5em;
    text-align: right;
    padding-right: 1.781em;
    margin-right: 1.250em;
    color: var(--black);
    margin-bottom: 32px;
    // word-break: break-all;
}
.ArticleReference {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    color: var(--blueColor);
    cursor: pointer;
}
.ArticleReferenceHover {
    font-family: Tajawal;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    display: none;
    width: 21.500em;
    height: 8.286em;
    background-color:var(--black);
    color: var(--white);
    text-align: right;
    padding: var(--px16);
    bottom: 140%;
    right: -1000%;
    border-radius: 4px;
    position: absolute;
    z-index: 40;
    cursor: pointer;
  }
  
  .ArticleReference:hover{
    .ArticleReferenceHover {
    visibility: visible;
    display: block;
  }

  } 
  .ArticleReference:hover::before {
    content: ''; 
    position: absolute;
    right: 4px; top: -11px;
    border: 9.6px solid transparent;
    z-index: 2;
    border-top: 9.6px solid var(--black);
  }
.ReferencesNumberedList {
    font-family: Tajawal;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    text-align: right;
}

.ArticleSummaryHeader {
    font-family: Cairo;
    font-size: 1.357em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.500em;
    text-align: right;
    margin-bottom: 0.421em;
    color: var(--secondaryHeader);
}

.ReferencesTitle {
    font-family: Cairo;
    font-size: 1.571em;
    font-weight: 700;
    line-height: 1.5em;
    color: var(--secondaryHeader);
    margin-bottom: 0.381em;
}

.ArticleHeader {
    font-family: Cairo;
    font-size: 1.429em;
    font-style: normal;
    font-weight: 700;
    line-height: 0.700em;
    text-align: right;
    margin-bottom: 1.600em;
}

.ArticleNumberedList {
    font-family: Tajawal;
    font-size: var(--px18);
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 32px;
    color: var(--black);
}

.DashboardArticle {
    text-align: right;
    margin-top:4.571em;
    button {    
        outline: none;
    }
    .videoWrapper {
        margin-bottom: 32px;
        width: 100%;
        height: 500px;
    }
    .imageContainer {
            object-fit: cover;
            width: 100%;
            height: auto;
    }
    .text-align-right {
        text-align: right;
    }
    .text-align-center {
        text-align: center;
    }
    .text-align-left {
        text-align: left;
    }
    .AddTextEditor {
        position: absolute;
        background-color: #FFFFFF;
        outline: none;
        border: 0;
        top: 43%;
        left: -45px;
    }
    .ArticleInfo {
        width: 18.214em;
        border: 1px solid #F5F5F6;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-top: 2.286em;
        padding: 0 1.714em 0 1.714em;
        .ImageElement {
            img {
                width: 100%;
            }
           
        }
        .ArticleOptions {
            .Numbers {
                font-family: Cairo;
                font-size: var(--px12);
                font-style: normal;
                font-weight: 600;
                line-height: 1.167em;
                text-align: center;
                margin-bottom: 2.083em;
                img {
                    margin-right: 0.444em;
                    margin-left: 0.444em;
                }
            }
            button {
                font-size: 1em;
                line-height: 1em;
                background: rgba(13, 102, 208,0.05);
                color: rgba(50, 50, 50, 0.25);
                width: 100%;
                height: 2.714em;
                margin-bottom: 2em;
            }
            .Flex {
                div {
                    cursor: pointer;
                }
                justify-content: space-around;
                color: var(--blueColor);
                font-family: Cairo;
                font-size: var(--px12);
                font-weight: 700;
                line-height: 1.167em;
                padding: 2em 0;
            }
        }
        .MainInterest {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid var(--lightGrey);
            padding-bottom: 1.214em;
            padding-top: 1.714em;
            .CheckBox label {
                font-family: Cairo;
                font-size: var(--px16);
                font-style: normal;
                font-weight: 600;
                line-height: 1.5em;
            }
            .Header {
                font-weight: 700;
                line-height: 1.714em;
                color: var(--secondaryHeader);
                img {
                    margin-right: 0.429em;
                }
            }
            @supports(-webkit-appearance: none) or (-moz-appearance: none) {
                input[type='checkbox'] {
                    border-color: var(--secondaryHeader);
                }:checked {
                    border-color: var(--blueColor) !important;
                    background-color: var(--blueColor);
                }
            }
            .DragAndDrop {
                text-align: center;
                font-family: Tajawal;
                width: 14.786em;
                height: 7.857em;
                border-radius: 5px;
                background-color: #F5F5F6;
                margin-top: 8px;
            }
            .SliderInfo {
                display: block;
                align-self: center;
                margin-top: 15px;
                color: var(--secondaryHeader);
                width: 224px;
                height: 256px;
                padding: 16px;
                background-color: var(--black);
                font-weight: 700;
                div {
                    margin-bottom: 8px;
                }
                img {
                    margin-left: 6px;
                }
            }
            .slidecontainer {
                width: 207px;
                height: 8px;
                  display: flex;
                  position: relative;
                  .rangeColored {
                    width: 0px;
                    height: 8px;
                    position: absolute;
                    background: linear-gradient(90deg, #2575FC 0%, #6A11CB 100%);
                    border-radius: 4px;
                    z-index: 1;
                  }
                  .Thumb {
                        position: absolute;
                        right: 0;
                        top: -7px;
                        width: 24px;
                        height: 24px;
                        z-index: 6;
                        border-radius: 50%;
                        background: #0D66D0;
                        background-image: url(../../assets/images/range/rangeStar.svg);
                        background-repeat: no-repeat;
                        background-size: 12px 12px;
                        background-position: center;
                        border: 2px solid #FFFFFF;
                        box-sizing: border-box;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    }
                  .rangeGray {
                    width: 209px;
                    height: 8px;
                    top: 0;
                    left: -1px;
                    position: absolute;
                    border-radius: 4px;
                    background-color: #F5F5F6;
                    z-index: 0;
                  }
                }
                .sliderLine {
                  position: absolute;
                  z-index: 1;
                  width: 1px;
                  height: 8px;
                  background-color: #FFFFFF;
                }
                
                .slider {
                  -webkit-appearance: none;
                  width: 207px;
                  height: 8px;
                  background: linear-gradient(90deg, #2575FC 0%, #6A11CB 100%);
                   outline: none;
                   border-radius: 5px;
                   border: 0;
                //   -webkit-transition: .2s;
                //   transition: opacity .2s;
                transition: background 0.2s ease-in-out;
                }
               
                .slider::-moz-range-track {
                    width: 207px;
                    height: 8px;
                    background: linear-gradient(90deg, #2575FC 0%, #6A11CB 100%);;
                    border: none;
                    border-radius: 3px;
                }
                
                .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 24px;
                height: 24px;
                z-index: 2;
                border-radius: 50%;
                background: #0D66D0;
                background-image: url(../../assets/images/range/rangeStar.svg);
                background-repeat: no-repeat;
                background-size: 12px 12px;
                background-position: center;
                border: 2px solid #FFFFFF;
                box-sizing: border-box;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                cursor: pointer;
               }
              
              .slider::-moz-range-thumb {
                width: 24px;
                height: 24px;
                z-index: 2;
                border-radius: 50%;
                background: #0D66D0;
                background-image: url(../../assets/images/range/rangeStar.svg);
                background-repeat: no-repeat;
                background-size: 12px 12px;
                background-position: center;
                border: 2px solid #FFFFFF;
                box-sizing: border-box;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                cursor: pointer;
              }
            }
    }
    .RulesOfWriting {
        background-color: var(--rulesBG);
        &Headers {
            padding: 1.667em 0;
            display: flex;
            justify-content: space-between;
            font-family: Cairo;
            font-size: var(--px18);
            font-weight: 700;
            line-height: 1.333em;
            img {
                margin-left: 0.333em;
            }
            .Link {
                cursor: pointer;
                font-size: 0.778em;
                font-weight: 600;
                line-height: 1.714em;
                color: var(--blueColor);
            }
        }
    }
    h1, h2, h3 {
        display: block;
    }
    .commentDivDraft {
        position: absolute;
        top: 0;
        right: -40px;
        width: 24px;
        height: 24px;
        .hoverComment {
            width: 200px;
            position: absolute;
            word-wrap: break-word;
            font-size: 16px;
            line-height: 32px;
            font-weight: 500;
            z-index: 20;
            border: 2px solid rgba(255, 51, 102, 1);
            background-color: #F5F5F6;
            border-radius: 4px;
            top: 26px;
            left: -75px;
        }
    }
    .HighlitedText {
        background-color: rgba(255, 51, 102, 0.25 );
        position: relative;
        user-select: none;
    }
    blockquote {
        border-right: 0.250em solid var(--blueColor);
        font-family: Tajawal;
        font-size: 2.286em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;
        padding-right: 1.781em;
        margin-right: 1.250em;
        color: var(--black);
        margin-bottom: 32px;
        // word-break: break-all;
    }
    .note{
        direction:rtl;
        box-sizing: border-box;
        padding:32px;
        border-radius: 4px;
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0;
        text-align: right;
         background-color: #FFF3CF;
         overflow: hidden;
    }
    
    .MainColumn {
        margin-top: 2.286em;
        .HighlitedTextEditor {
            background-color: rgba(#339704, 0.25 ) !important;
            position: relative;
            user-select: none;
        }
        .References {
            color: var(--secondaryHeader);
            display: flex;
            flex-direction: column;
            justify-content: baseline;

            &Item {
                position: relative;
                .DeleteReference {
                    display: none;
                    text-align: center;
                    color: white;
                    width: 20px;
                    height: 20px;
                    top: 0px;
                    right: -35px;
                    position: absolute;
                }
                &:hover {
                    .DeleteReference { 
                        display: block;
                    }
                }
            }

            .Header {
                font-family: Cairo;
                font-size: 1.357em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.553em;
                span{
                    font-size: 16px;
                    margin-right: 10px;
                    font-weight: normal;
                }
            }
            .Text {
                font-family: Tajawal;
                font-size: var(--px18);
                font-weight: 500;
                line-height: 1.5em;          
            }
        }
        .Input {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.176em;
            background-color: #F5F5F6;
            border-radius: 0.294em;
            font-family: Cairo;
            font-size: 1.214em;
            font-weight: 700;
            line-height: 0.824em;
            border: none;
            text-align: right;
            color: var(--secondaryHeader);
            width: 42.941em;
            input {
                height: 3.529em;
                border: 0px;
            }
        }::placeholder {
            font-weight: 700;
        }
    }
    .TextEditor {
        user-select: auto;
        -webkit-user-select: auto;
        width: 100%;
        padding: 10px;
        border: 1px solid var(--grayColorMain);
        background-color: var(--lightGrey);
        font-family: Tajawal;
        font-size: var(--px18);
        font-style: normal;
        font-weight: 500;
        line-height: 1.5em;
        letter-spacing: 0px;
        ul {
            li {
                list-style-image: url("../../assets/images/Dashboard-article/bulletedListDot.svg");
            }
        }
        &BlockQuote {
            color: var(--secondaryHeader);
            font-family: Tajawal;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0px;
            text-align: right;
            margin-top: 24px;
            margin-bottom: 24px;
        }
        &Note {
            direction:rtl;
            box-sizing: border-box;
            padding:32px;
            border-radius: 4px;
            font-family: 'Tajawal';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0;
            text-align: right;
            background-color: #FFF3CF;
            overflow: hidden;
        }
        .videoButton {
            margin-left: 0.571em;
            font-family: Cairo;
            font-size: 0.778em;
            font-weight: 700;
            line-height: 1em;
            width: 9.286em;
            height: 2.714em;
        }
        .youtubeVideo {
            text-align: center;
            img {
                margin-bottom: 0.889em;
                width: 2.667em;
                height: 2.667em;
            }
        }
        &Buttons {
            width: 100%;
            border: 1px solid var(--grayColorMain);
            border-bottom: 0px;
            button {
                height: 2.714em;
                padding: 0.5em 0.571em;
                background: var(--white);
                user-select: none;
                border: 0;
                border-left: 1px solid var(--grayColorMain);
            }
            .Settings {
                position: relative;
                .SettingsDropDown {
                    display: flex;
                    flex-direction: column;
                    left: 0;
                    top: 35px;
                    border-right: 0px;
                }
                button {
                    border-left: 0;
                    border-right: 1px solid var(--grayColorMain);
                }
            }
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .DropDownItems{
                position: relative;
                cursor: pointer;
                border-left: 1px solid var(--grayColorMain);
                text-align: center;
                padding: 0.5em 0.571em;
                &Absolute {
                    z-index: 100;
                    display: none;
                    position: absolute;
                    background: #FFFFFF;
                    border: 1px solid #F5F5F6;
                    box-sizing: border-box;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
                    border-radius: 4px;
                    button {
                        display: flex;
                        border-left: 0;
                        font-family: Cairo;
                        font-size: 1em;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 2.429em;
                        height: 2.857em;
                        align-items: center;
                        img {
                            margin-left: var(--px16);
                        }
                    }
                }
            }
        }
        button {
            width: 40px;
            height: 40px;
        }
    }
}
.SeletectedTextToolTip {
    display: flex;
    width: 25.571em;
    height: 10.929em;
    button {
        width: 5.714em;
        height: 2.714em;
        font-weight: 700;
        font-size: 1em;
        line-height: 1em;
        margin: var(--px16) 0 0 var(--px16);
        outline: none;
    }
    textarea {
        color: var(--secondaryHeader);
        font-family: Tajawal;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
        padding: 8px;
        outline: none;
        text-decoration: none;
        border: 1px solid #E8E8E8;
        background-color: #F6F6F6;
        border-radius: 5px;
        width: 238px;
        height: 122px;
        margin: 16px 16px 16px 8px;
        resize: none;
    }
}
.SeletectedTextToolTip::before, .SeletectedTextToolTip::after {
    content: ''; 
    position: absolute;
    left: 12em; top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #CACACA;
   }
   .SeletectedTextToolTip::after {
    border-bottom: 10px solid white;
    top: -19px; 
   }
.PrivacyContent {
    margin-top:4.571em;
    margin-bottom: 32px;
    ul {
        text-align: right;
    }
    ol { 
        text-align: right;
    }
    li {
        font-family: Tajawal;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
                text-align: right
    }
}
.PrivacyInterests {
    display: flex;
    div {
        margin-left: 16px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        height: 36px;
        border-radius: 4px;
        background: #F6F6F6;
        padding: 7px 8px;
    }
    margin-bottom: 63px;
}
.PrivacyLike {
    display: flex;
    flex-direction: column;
    width: 99px;
    height: 99px;
    border-radius: 50%;
    background-color: #F5F5F6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--blueColor);
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    margin-bottom: 16px;
    img {
        width: 40px;
        height: 40px;
    }
}
.PrivacyViewers {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #CACACA;
    padding-bottom: 3.5px;
    margin-bottom: 32px;
}
.PrivacySubAuthorData {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    &Info {
        margin-right: 16px;
        display: flex;
        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
        button {
            margin-right: 8px;
            width: 78px;
            height: 24px;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
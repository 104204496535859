.ReportContentModal {
    text-align: right;
    .ReportContentModalInput {
        width: 100%;
        padding: 0 24px 0 26px;
        margin-bottom: 24px;
        height: 66px;
        textarea {
            padding: 8px;
            width: 100%;
            border: 0px;
            background-color: #EDEDED;
            border-radius: 4px;
            height: 66px;
            -moz-appearance: none;
            resize: none;
            outline: none;
        }
    }
    .Thanks {
        padding: 2.714em 1.714em 2.286em 1.714em;
        h1 {
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.5em;
        }
        p {
            font-family: Tajawal;
            font-size: var(--px16);
            font-weight: 500;
            line-height: 1.5em;
        }
    }
    .Header {
        padding: 22px 23px 24px 0;
        border-bottom: 1px solid var(--borderGray);
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: right;

    }
    .SubHeader {
        padding: 32px 24px 13px 0;
        font-family: Cairo;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0px;
        text-align: right;
    }
    .Text {
        padding: 0 24px 16px 24px;
        font-family: Tajawal;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
    }
    .RadioButtons {
        padding: 0 24px  24px 2.286em;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
        input {
            margin-bottom: var(--px16);
            font-family: Cairo;
        }
    }
    .Buttons {
        border-top: 1px solid var(--borderGray);
        padding: 1.714em 2.286em 1.429em 0 ;
        button {
            margin-left: 1.714em;
            font-size: 1em;
            width: 7.143em;
            height: 2.714em;
        }
        .ButtonReject {
            background-color: var(--alert);
            width: 195px;
        }
    }
}
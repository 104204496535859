.Input {
    position: relative;
    margin-bottom: 2.5em;
    .iconRedStar {
        margin-left: 0.286em;
        margin-right: 0.286em;
    }
    .inputPrompt {
        font-family: Cairo;
        font-size: var(--px12);
        font-style: normal;
        font-weight: 400;
        line-height: 1.500em;
        color: var(--secondaryHeader);
    }
    input {
        position: relative;
        width: 100%;
        padding: 4px 0;
        z-index: 1;
        border: 1px solid var(--black);
        border-radius: 4px;
        outline: none;
        font-family: var(--defaultFontFamily);
        font-size: 1em;
        line-height: 1.5em;
        padding-right: 0.929em;
        letter-spacing: 0.13125px;
        color: var(--textColor);
        background-color: transparent;
    }::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      position: absolute;
      right: 0;
     
  }
}
.CheckBox {
    position: relative;
    display: flex;
    font-size: 1em;
    
    label {
        font-weight: 700;
        font-size: 1em;
        line-height: 1.5em;
        transition: all 0.2s ease-in-out;
        user-select: none;
    }
}

.exclamation {
    position: relative;
    display: inline-block;
  }
  
  .exclamation .exclamationtext {
    font-family: Tajawal;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5em;
    visibility: hidden;
    width: 21.500em;
    height: 5.286em;
    background-color:var(--black);
    color: var(--white);
    text-align: right;
    padding: var(--px16);
    bottom: 150%;
    right: -50%;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
  }
  
  .exclamation:hover .exclamationtext {
    visibility: visible;
  }

  .exclamation:hover::before {
    content: ''; 
    position: absolute;
    right: 4px; top: -11px;
    border: 9.6px solid transparent;
    z-index: 2;
    border-top: 9.6px solid var(--black);
  }

  .ErrorText {
        font-family: Cairo;
        font-size: var(--px12);
        font-style: normal;
        font-weight: 400;
        line-height: 1.5em;
        text-align: right;
        color: var(--alert);

  }

  @supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      --active: #fff;
      --active-inner: #0D66D0;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #0D66D0;
      --border-hover: #0D66D0;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 20px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin-left: 0.714em;
      cursor: pointer;
      border: 2px solid var(--secondaryHeader);
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: -2px;
        top: -2px;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
          border: 2px solid #0D66D0;
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:not(.switch) {
        width: 20px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 4px;
        background-color: var(--white);
        &:after {
          width: 8px;
          height: 14px;
          border: 3px solid var(--white);
          border-top: 0;
          border-left: 0;
          left: 4px;
          top: -1px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
          background-color: var(--blueColor);
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }
    input[type='radio'] {
      border-radius: 50%;
      &:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, .7));
      }
      &:checked {
        --s: .5;
      }
    }
  }
.CommunityPicks {
    margin-bottom: 4.571em;
    .iconCommunityPicks {
        border-radius: 4px;
        width: 25.000em;
        height: 19.643em;
        object-fit: cover;
    }
    .interest {
        font-size: var(--px18);
        font-weight: 700;
        line-height: 1.778em;
        text-align: right;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    &Author {
        cursor: pointer;
        font-family: Cairo;
        font-size: 1.071em;
        font-weight: 700;
        line-height: 1.067em;
        text-align: right;
        .CommunityPicksPhoto32 {
            width: 32px;
            height: 32px;
        }
        span {
            margin-left: 0.317em;
            margin-right: 0.533em;
        }
    }
    .numbers {
        font-family: Cairo;
        font-size: 0.929em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.538em;
        text-align: right;
        span {
            font-weight: 700;
            text-align: right;
        }
    }
}

@media screen and (max-width: 767px){
    .CommunityPicks{
       .iconCommunityPicks {
           width: 100%;
           height: 100%;
       }
    }
}

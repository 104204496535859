.WritersWorthFollowing {
    .container {
        height: 100%;
        .writersContent { 
            border-radius: 4px;
            cursor: pointer;
            height: 16.1em;
            background-color: var(--lightGrey);
            margin-bottom: 3.857em;
            text-align: center;
            display: flex;
            flex-direction: column;
            .iconCircle {
                margin-top: 2.286em;
                width: 5.1em;
                height: 72px;
                margin-left: auto;
                margin-right: auto;
                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .text{
                height: 1.714em;
                line-height: 1.714em;
                margin-bottom: 0px;
                &Flex {
                    margin-top: 0.571em;
                    height: 1.714em;
                    display: flex;
                    justify-content: center;
                    p{
                        span {
                            font-size: var(--px18);
                            font-weight: 700;
                        }
                    }
                }
                p{
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }    
}
.writersHeader {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
}

.smallLine {
    height: 2px;
    width: 150px;
    background-color: black;
    margin-bottom: 2.857em;
}

@media screen and (max-width: 767px){
    .WritersWorthFollowing {
        .container {
            .writersContent {     
            margin-bottom: 0.571em;    
            width: 100% !important;
            }
        }
    }
}
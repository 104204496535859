.LatestRandomArticle {
    overflow: hidden;
    max-height: 25.143em;
    &Photo {
        img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        }
        padding-right: 0 !important;
        padding-left: 0 ;
    }
    &Content {
        background-color: var(--lightGrey);
        text-align: right;
        padding-right: 24px;
        min-height: 300px;
        .contentTitleBlue{
            cursor: pointer;
            color: var(--blueColor);
            font-size: 1.428em;
            font-weight: 700;
            line-height: 2.2em;
            margin-bottom: 16px;
        }
        .contentTitleBlack {
            cursor: pointer;
            font-size: 1.71em;
            font-weight: 700;
            line-height: 1.5em;
        }
        .contentText {
            color: var(--secondaryHeader);
            font-weight: 500;
            font-size: 1.428em;
            line-height: 1.333em;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
    }
    .moreArticles {
        text-align: right;
        color: var(--blueColor);
        display: flex;
        div {
            margin-left: 0.5em;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 1.285em;
            font-weight: 700;
            line-height: 1.5em;
        }
    }
}
@media screen and (max-width: 767px){
    .LatestRandomArticle{
        flex-direction: column;
        &Photo {
            width: 100%;
            padding-left: 0;
        }
        &Content {
            padding-bottom: 1em;
        }
    }
}
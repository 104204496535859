.BeforePublishing {
    text-align: right;
    .Header {
        padding: 1.333em 1.333em 1.333em 1.333em;
        font-family: Cairo;
        font-size: var(--px18);
        font-weight: 700;
        line-height: 1.5em;
        border-bottom: 1px solid var(--borderGray);
    }
    .Content {
        padding: 2.286em 1.714em 2.286em 1.714em;
        border-bottom: 1px solid var(--borderGray);
        h1 {
            font-family: Cairo;
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.5em;
        }
        .Time {
            margin-top: 1em;
            font-family: Cairo;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 700;
            line-height: 1.333em;
            color: var(--secondaryHeader);
            span {
                color: var(--alert);
                margin-right: 0.2em;
            }
        }
        .Text {
            margin-top: 0.929em;
            font-family: Tajawal;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
        }
    }
    .Buttons {
        display: flex;
        padding: 1.714em;
        .buttonPublish{
            margin-left: 1.714em;
            font-size: 1em;
            line-height: 1.714em;
            width: 13.929em;
            height: 2.857em;
        }
        .buttonClose {
            font-size: 1em;
            line-height: 1.5em;
            width: 6.286em;
            height: 2.857em;
        }
    }
}
.CareersPage {
    overflow-x: hidden;
    margin-top:4.571em;
    &Header {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(../../../assets/images/StaticPages/Careers/CareersBG.png);
        background-repeat: no-repeat;
        width: 100%;
        height: 37.14em;
        background-size: cover;
        text-align: right;
        display: flex;
        align-items: center;
        flex-direction: column;
        &Content {
            color: var(--white);
            font-family: Cairo;
            text-align: right;
            button {
                background-color: transparent;
                color: var(--white);
                border: 1px solid var(--white);
                width: 99px;
                height: 38px;
            }
            .bigText {
                margin-left: 5.5px;
                margin-right: 10px;
                font-size: 2em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5em;
            }
            .smallText {
                margin-left: 13px;
                margin-right: 13px;
                font-size: var(--px16);
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
            }
            .header1 {
                display: block;
                color: var(--white);
                font-weight: 700;
                font-size: 3em;
                line-height: 1.5em;
            }
            .header2 {
                margin-left: auto;
                margin-right: auto;
                width: 33.182em;
                display: block;
                color: var(--white);
                font-weight: 700;
                font-size: 1.571em;
                line-height: 2em;
            }
        }
        .AppHeader {
            background-color: transparent !important;
        }
    }
    &Content {
        .CareersAuthor{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .CareersAuthorPhoto {
                display: flex;
                align-items: center;
                .AuthorDataImage {
                    width: 40px;
                    height: 40px;
                }
                .AuthorPhotoSpan {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    margin-left: 4px;
                    margin-right: 8px;
                }
                button {
                    width: 78px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 14px;
                    margin-right: 8px;
                }
            }
            .CareersAuthorText {
                img {
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }
        .moreCareers {
            margin-top: 22px;
            color: var(--blueColor);
            display: flex;
            cursor: pointer;
            justify-content: flex-end;
            div {
                margin-left: 0.759em;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 1.286em;
                font-weight: 700;
                line-height: 1.333em;
            }
        }
        &FirstColumn{
            p {
                font-family: Tajawal;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
                text-align: right;
            }
            h4 {
                text-align: right;
                font-family: Cairo;
                font-size: 1.429em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5em;
                margin-bottom: 0.650em;
            }
            h3 {
                font-weight: 700;
                font-size: 32px;
                line-height: 1.5em;
            }
            .smallLine {
                border: 2px solid var(--black);
                width: 9em;
            }
            &Content {
                &FirstBlock {
                    width: 825px;
                    height: 340px;
                }
                &SubBlock {
                    width: 398px;
                    height: 340px;
                }
                .smallHeader {
                    text-align: right;
                    font-weight: 600;
                    color: var(--secondaryHeader);
                    font-size: 22px;
                    line-height: 2em;
                }
                .text {
                    text-align: right;
                    font-weight: 500;
                    color: var(--secondaryHeader);
                    font-size: 1.28em;
                    line-height: 1.5em;
                }
                .content {
                    text-align: right;
                    width: 100%;
                    border: 1px solid var(--gray);
                    border-radius: 4px;
                    padding-bottom: 1.786em;
                    &Block {
                        margin-right: 2em;
                        &Header {
                            font-size: 1.28em;
                            line-height: 1.5em;
                            font-weight: 700;
                        }
                        &Text {
                            width: 85%;
                            font-size: 1.28em;
                            line-height: 1.5em;
                            font-weight: 500;
                            color: var(--secondaryHeader);
                        }
                        &Btn {
                            width: 9em;
                            height: 3em;
                            font-size: 1em;
                            line-height: 1em;
                        }
                    }
                }
            }
        }
        &SecondColumn {
            &Disabled {
                text-align: right;
                font-weight: 700;
                line-height: 2.5em;
                color: var(--grayColorMain);
            }
            &Menu {
                text-align: right;
                font-weight: 700;
                line-height: 2.5em;
                border-bottom: 1px solid var(--grayColorMain);
            }
        }
    }
}

@media screen and (max-width: 767px){
    .CareersPage {
        &Header {
            &Content {
                .header2 {
                    width: 100%;
                    font-size: 1.286em;
                }
                .header1 {
                    font-size: 2.286em;
                }
            }
        }
    }
}
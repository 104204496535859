.PartnerBlock {
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--grayColorMain);
    margin-bottom: 2.286em;
    margin-top:4.571em;
    &Text {
        padding: 59px 27px 20px 27px;
        display: flex;
        justify-content: center;
        text-align: right;
        flex-direction: column;
        background-color: var(--rulesBG);
        border-left: 1px solid var(--grayColorMain);
        font-family: Tajawal;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
    }
    &Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 225px;
    }
        img{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 305px;
            height: 225px;
        }
}
.InterestsLandingPage {
    overflow-x: hidden;
    margin-top:4.571em;
    &Item {
        width: 25.000em;
        height: 16.000em;
        background-color: var(--white);
        border: 0.071em solid var(--grayColor);
        border-radius: 4px;
       &Images {
        display: flex;
        cursor: pointer;
        height: 11.429em;
        .itemImage {
            border-radius: 5px 0px 0px 0px;
            width: 85%;
            background-image: url(../../assets/images/interestsLandingItem.png);
            background-size: cover;
            cursor: pointer;
            img {
                border-radius: 5px 0px 0px 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .itemIconPlus {
            width: 15%;
            background-color: var(--lightGrey);
            border: 0.071em solid var(--grayColor);
            border-radius: 0 0.286em 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 1.571em;
                height: 1.571em;
            }
        }
        .iconCheckmarkPure {
            width: 1.857em;
            height: 1.429em;
            margin-top: auto;
            margin-bottom: auto;
        }
       }
       &Title {
        font-size: var(--px18);
        font-weight: 700;
        line-height: 1.889em;
        text-align: right;
        display: flex;
        height: 3.556em;
        padding-right: 1.111em;
            div{
                margin-top: auto;
                margin-bottom: auto;
            }
       }
       
    }
    &Headers {
        border-bottom: 0.143em solid #CACACA;
        .Header {
            font-size: 2em;
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;

        }
        .SubHeader {
            font-size: 1.429em;
            font-weight: 600;
            line-height: 1.5em;
            text-align: right;
        }
        &Container {
            display: flex;
            .HeaderContainer {
                cursor: pointer;
                font-size: 2.286em;
                font-weight: 700;
                line-height: 1.5em;
                text-align: right;
                span {
                    border-bottom: 4px solid var(--black);
                }
            }
            img {
                cursor: pointer;
                margin-right: 1.857em;
                align-self: center;
                width: 2.857em;
                height: 2.857em;
            }
        }
    }
}
@media screen and (max-width: 767px){
    .InterestsLandingPage {
        overflow-x: hidden;
        &Item { 
            width: 100%;
            &Images { 
                .itemImage {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

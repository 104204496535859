.writersSignup {
    display: flex;
    justify-content: flex-end;
    .inlineFlexDropdown {
        width: 13.571em;
    }
    
    .thirdStepText {
        font-family: Cairo;
        font-size: 1em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5em;

    }
    &Content {
        width: 29.429em;
        text-align: right;
        margin-top: 12.6em;
        margin-left: 6.8em;
        line-height: 1.5em;
        .btnContent {
            img {
                margin-left: 0.571em;
            }
        }
        .forgetPassword {
            display: flex;
            color: var(--blue);
            font-weight: 600;
        }
        &Btn {
            width: 100%;
            font-size: 1em;
        }
        &Line {
            display: flex;
            text-align: center;
            div {
                display: table-cell;
                margin-top: auto;
                margin-bottom: auto;
            }
            hr {
                width: 100%;
                border-top: 1px solid var(--grayColor);
            }
        }
        &Inputs {
            position: relative;
            
            .form1 {
                position: relative;
            }
            
            .inlineFlex {
                display: inline-flex;
                font-size: 1em;
                .Input {
                    margin-left: 2.143em;
                    width: 13.571em;
                }
                
            }
            .text {
                font-weight: 600;
                line-height: 1.5em;
            }
            .nextStepText {
                font-size: 1em;
                font-weight: 700;
                line-height: 1.5em;
                color: var(--secondaryHeader);
                div {
                    position: absolute;
                    right: -45px;
                    line-height: 1.8em;
                }
                img {
                    position: absolute;
                    right: -45px;
                }
            }
            .Input {
                label {
                    font-weight: 600;
                    line-height: 1.5em;
                    img {
                        margin-right: 0.2em;
                        margin-bottom: 0.7em;
                    }
                    .exclamation {
                        margin-bottom: 0em;
                    }
                }
                input {
                    height: 2.714em;
                    background-color: var(--lightGrey);
                    border-color: #EDEDED;
                }::-webkit-input-placeholder {
                    padding-top: 3px;
                }
                input::-webkit-contacts-auto-fill-button {
                    visibility: hidden;
                    display: none !important;
                    pointer-events: none;
                    position: absolute;
                    right: 0;
                    width: 0;
                  }
            }
            
        }
        &Text {
            line-height: 1.5em;
            .subText {
                font-size: var(--px18);
                font-weight: 600;
                line-height: 1.874em;
            }
            .textTitle {
                font-size: var(--px18);
                font-weight: 700;
                &Secondary {
                    font-family: Cairo;
                    font-size: 1em;
                    font-weight: 600;
                }
                &Sub {
                    position: relative;
                    font-family: Cairo;
                    font-size: 1em;
                    font-weight: 700;
                    div {
                        position: absolute;
                        top: 0;
                        right: -45px;
                        line-height: 1.7em;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        right: -45px;
                    }
                }
            }
        }
        &Logos {
            display: flex;
            .Logo {
                img{
                    width: 2.5em;
                    height: 2.5em;
                    margin-left: 0.321em;
                }
               
            }
        }
    }
    .SecondPicture {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(../../../assets/images/signup-login/writer-signup-step2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .ThirdPicture {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(../../../assets/images/signup-login/writer-signup-step3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    &Picture {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        width: 50%;
        height: 100vh;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(../../../assets/images/signup-login/writer-signup-step1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var(--black);
        &Content {
            margin-top: 50vh;
            margin-right: 7.35em;
            display: flex;
            .icon {
                width: 40px;
                margin-top: 0.429em;
                img {
                    opacity: 1
                }
            }
            
            .text {
                color: var(--white);
                font-weight: 700;
                font-size: 1.43em;
                line-height: 1.874em;
                width: 19em;
                text-align: right;
            }
        }
    }
    .form-row {
        margin: 0;
    }
}

.line{
    position: absolute;
    width: 1px;
    background: #E0E0E0;
    right: -33px;
}
.Spacer {
    position: relative;
}

.StepActive {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--white);
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    background-color: #0D66D0;
}
.StepDisabled {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--white);
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    background-color: var(--secondaryHeader);
}
.writers-signupBtn {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-family: Cairo;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;

}

@media screen and (max-width: 767px){
    .writersSignup {
        overflow-x: hidden;
        flex-direction: column;
        margin-right: 1.071em;
        margin-top: 2.286em;
        margin-left: 1.071em;
        &Content {
            width: 100%;
            margin-left: 1em;
            margin-right:0;
            margin-top: 0;
            &Inputs {
                .inlineFlex {
                    display: block;
                    .Input {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        }
        &Picture {
            width: 100%;
            display: none;
        }
    }
}
@media screen and (max-width: 1050px){
    .writersSignup {
        &Content {
            margin-left: 1em;
            margin-right: 0;
        }
        
        &Picture {
            width: 40%;
            //display: none;
            &Content{ 
                margin-right: 3em;
            }
        }
    }
}

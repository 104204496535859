.Testimonials {
    .testimonialsHeader {
        border-right: 8px solid var(--blueColor);
        margin-right: 4em;
        text-align: right;
        padding-right: 1.781em;
        font-family: var(--secondaryFontFamily);
        font-size: 2.286em;
        line-height: 1.5em;
        font-weight: 700;
    }
    .iconCircle {
        border-radius: 500px;
        width: 4em;
        height: 4em;
        margin-top: 2vh;
        margin-left:15vh;
        margin-right: auto;
        p {
            font-weight: 700;
            font-size: 16px;
        }
    }
    .slider {
        width: 11.5em;
        height: 14.143em;
        p {
            font-size: var(--px16);
            font-weight: 700;
            margin-bottom: 2.286em;
        }
        .carousel-item {
            p{
                margin-right: 0.5em;
            }
            img {
                width: 7.143em;
                height: 7.143em;
                object-fit: cover;
            }
        }
        .carousel {
            width: 10em;
            display: block;
            margin-bottom: auto;
        }
        .carousel-indicators {
            right: none;
            bottom: -1em;
            left: -1em;
            margin-right: 0;
            
         }
    
         
         .carousel-indicators li {
            
             border: 1px solid var(--black);
             border-radius: 500px;
             width: 11px;
             height: 11px;
             background-color: var(--white);
         }
         .carousel-indicators .active {
             border: 1px solid var(--black);
             border-radius: 500px;
             width: 11px;
             height: 11px;
             background-color: #293CEA;
         }
    }
}


@media screen and (max-width: 767px){
    .Testimonials {
        .slider {
            align-self: center;
        }
        .Flex {
            flex-direction: column;
        }
        .testimonialsHeader {
            font-size: 1.143em;
            padding-right: 1.250em;
            margin-right: 0;
        }
    }
}

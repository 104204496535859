.searchDropdown {
        top: 50px;
        border-radius: 0 0 4px 4px;
        border: 1px solid #D8DCE6;
        border-top: none;
        margin-top: -4px;
        color: var(--black);
        position: absolute;
        background-color: var(--white);
        width: 31.333em;
        z-index: 3;
    .NoResult {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        font-family: Cairo;
        line-height: 48px;
    }
    &Articles {
        margin-right: 1.143em;
        margin-left: 1.143em;
        margin-top: 1em;
        &Header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D8DCE6;
            padding-bottom: 0.714em;
            &Title {
                font-family: Cairo;
                font-size: var(--px16);
                font-style: normal;
                font-weight: 700;
                line-height: 1.375em;
                text-align: right;

            }
            &More {
                cursor: pointer;
                color: var(--blueColor);
                font-family: Cairo;
                font-size: 1em;
                font-style: normal;
                font-weight: 700;
                text-align: center;

            }
        }
        &Content {
            font-family: Cairo;
            font-size: 0.933em;
            font-style: normal;
            font-weight: 600;
            line-height: 1.786em;
            text-align: right;
            cursor: pointer;
            img {
                border-radius: 4px;
                object-fit: cover;
                width: 40px;
                height: 40px;
                margin-left: 1em;
            }
        }
    }
}
.request-form{
    direction: rtl;
    text-align: right;
    text-align: right;
    display: block;
    width: 100%;
    label{
        display: block;
        input{
            width: 100%;
            display: block;
            height: 50px;
            min-width: 300px;
            border: 0;
            border-bottom: 1px solid black;
        }
        textarea{
            width: 100%;
            display: block;
            height: 50px;
            min-width: 300px;
            border: 0;
            border-bottom: 1px solid #000;
        }
    }
}
.Btn {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    width: 17.5em;
    height: 3.625em;
    background-color: var(--blueColor);
    border: none;
    border-radius: 4px;
    color: var(--white);
    font-family: var(--defaultFontFamily);
    font-weight: 700;
    font-size: 1.143em;
    line-height: 0.875em;
    cursor: pointer;
    user-select: none;
    outline: none !important;
    &Small {
        width: 15.4em;
        height: 2.71em;
        font-size: 0.875em;
        &Colorless {
            border: 1px solid var(--blueColor);
            background-color: var(--white);
            color: var(--blueColor);
        }
        &Opacity{
            background: rgba(13,102,208,0.09);
            color: var(--black) ;
        }
        &Gray {
            border: 1px solid #DDDDDD;
            background-color: var(--white);
            color: var(--black);
        }
    }
    &Middle {
        width: 17.28em;
        height: 3.42em;
        font-size: 0.875em;
        display: flex;
        align-items: center;
        img {
            margin-left: 0.357em;
            align-self: center;
        }
        &Colorless {
            border: 1px solid var(--blueColor);
            background-color: var(--white);
            color: var(--blueColor);
        }
    }
    &Colorless {
        border: 1px solid var(--blueColor);
        background-color: var(--white);
        color: var(--blueColor);
        border-radius: 4px;
        font-size: 0.875em;
        &UltraSmall {
            border: 1px solid var(--blueColor);
            background-color: var(--white);
            color: var(--blueColor);
            width: 8.3em;
            height: 2em;
            font-size: 0.750em;
        }
    }
    
}
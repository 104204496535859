.interests {
    
    h3 {
        font-size: 22px;
        line-height: 33px;
        font-weight: 600;
        justify-content: center;
        text-align: center;
        margin-bottom: 0;
    }
    h1 {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        justify-content: center;
        text-align: right;
        margin-bottom: 0;
    }
    flex: none !important;
    &Registration {
        display: flex;
        justify-content: center;
        font-size: 1.357em;
        font-weight: 700;
        button {
            width: 280px;
            height: 58px;
            font-size: 16px;
            font-weight: 700;
            line-height: 14px;
        }
        p {
            font-family: 'Tajawal';
            margin-top: auto;
            margin-bottom: auto;
            color: var(--secondaryHeader);
            span {
                color: var(--blueColor)
            }
        }
    }
    .moreInterests {
        color: var(--blueColor);
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        div {
            margin-left: 0.759em;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 1.286em;
            font-weight: 700;
            line-height: 1.333em;
        }
    }
}
.interestsContent { 
    background-color: gray;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    width: 11.7em;
    height: 6.9em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
    text-align: center;
    font-size: 1.286em;
    font-weight: 700;
    color: var(--white);
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.interestsText {
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    padding: 20px;
    cursor: pointer;
    z-index: 999;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blackBgOpcitity{
    background: black;
    opacity: 0.3;
    width: 11.7em;
    height: 6.9em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    position: absolute;
}

@media screen and (max-width: 767px){
    .interests {
        h1 {
            font-size: 1.714em;
        }
        h3 {
            font-size: var(--px16);
        }
        &Registration {
            display: block;
            text-align: center;
            font-size: 1.143em;
            button {
                width: 100%;
            }
            p {
                margin-top: 0.571em;
                font-size: 1em;
            }
        }
    }
    .interestsContent,.blackBgOpcitity {
        // width: 8.889em;
        width: 45%;
        height: 5.222em;
    }
}

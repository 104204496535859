.searchPage {
    max-width: 100%;
    overflow-x: hidden;
    text-align: right;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 4.571em;
    .container {
        flex: 1 0 auto;
    }
    .SuggestedArticlesListMostReadInterests {
        margin-top: 3.5em;
    }
    .ContentNotFoundHeader {
        font-family: Cairo;
        font-size: 1.571em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;

    }
    .ContentNotFound {
        display: flex;
        img {
            width: 25.000em;
            height: 28.000em;
            object-fit: cover;
        }
        button {
            width: 9.286em;
            height: 3.429em;
        }
        &Titles {
            margin-top: auto;
            margin-bottom: auto;
            h1 {
                font-family: Cairo;
                font-size: 1.571em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.091em;
                text-align: right;
                margin-bottom: 0;
            }
            &Text {
                font-family: Cairo;
                font-size: var(--px18);
                font-style: normal;
                font-weight: 600;
                line-height: 1.5em;
                color: var(--secondaryHeader);
            }
        }
    }
    &Content {
        min-height: 40vh;
        &Interests{
            border-bottom: 2px solid var(--borderGray);
        .InterestsSearchItem {
            display: flex;
            justify-content: space-between;
            &Text {
                font-family: Tajawal;
                font-size: var(--px16);
                font-style: normal;
                font-weight: 500;
                line-height: 1.5em;
                text-align: right;
                color: var(--secondaryHeader);
            }
            &Title {
                font-family: Cairo;
                font-size: 1.571em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.818em;
                text-align: right;
                cursor: pointer;
            }
            &Numbers {
                button {
                    margin-right: 1.143em;
                }
                span {
                    font-weight: 700;
                }
                font-family: Cairo;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5em;
                letter-spacing: 0px;
                text-align: right;
                color: var(--secondaryHeader);
            }
        }
        .bg {
            border-radius: 4px;
            overflow: hidden;
            width: 52.143em;
            height: 17.857em;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            
        }}
        &Btn {
            width: 100%;
            font-family: Cairo;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            line-height: 1em;
            text-align: center;
            background-color: var(--blueColor);
            height: 3.429em;
        }
    }
    .whyMawsuah{
        border-radius: 4px;
        width: 18.2em;
        height: 19.78em;
        background-color: var(--black);
        text-align: right;
        margin-top: 4.571em;
        &Title {
            font-size: 1.286em;
            font-weight: 700;
            line-height: 1.8em;
            color: var(--white);
            margin-right: 1.71rem;
            margin-left: 1.9rem;
            padding-top: 1.7777em;
        }
        &Text {
            height: 9.000em;
            font-family: var(--secondaryFontFamily);
            font-size: 1.143em;
            font-weight: 400;
            line-height: 1.5em;
            color: var(--white);
            margin-right: 1.71rem;
            margin-left: 1.9rem;
        }
        &Btn {
            text-align: center;
            .registerBtn{
                font-size: 1em;
                font-weight: 700;
                line-height: 1em;
                width: 14.28em;
                height: 3.42em;
            }
        }
    }
    &Header {
        display: flex;
        border-bottom: 2px solid var(--secondaryHeader);
        padding-bottom: 1.071em;
        margin-top: 4.571em;
        h1 {
            font-family: Cairo;
            font-size: 1.714em;
            font-weight: 700;
            line-height: 2em;
            text-align: right;
            margin-bottom: 0;
        }
        img {
            margin-left: 1.5em;
            margin-top: 0.7em;
            width: 25px;
            height: 25px;
        }
    }
    &Menu {
        border-bottom: 1px solid var(--gray);
        display: flex;
        &Item {
            cursor: pointer;
            color: var(--secondaryHeader);
            margin-bottom: 0;
            font-family: Cairo;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 600;
            line-height: 1.375em;
            padding: 0.571em var(--px16);
        }
        .active {
            font-weight: 700;
            color: var(--black);
            border-bottom: 2px solid var(--black);
            margin-bottom: -1px;
        }
    }
    &Filter {
        display: flex;
        &Item {
            cursor: pointer;
            margin-bottom: 0;
            font-family: Cairo;
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: 1em;
            text-align: right;
            margin-left: 1.714em;
        }
        .Active {
            color: var(--blueColor);
            font-weight: 700;
        }
    }
    .WritersSearchNumbers {
        font-family: Cairo;
        font-style: normal;
        font-weight: 700;
        line-height: 1.714em;
        color: var(--secondaryHeader);
        .spanBold {
            margin-right: 0.214em;
            color: var(--black);
            font-weight: 700;
            cursor: pointer;
        }
        span{
            margin-right: 0.2em;
            font-weight: 600;
        }
    }
    .WritersSearchBtn {
        width:9.286em;
        height: 2.714em;
        background-color: var(--white);
        color: var(--blueColor);
        border: 1px solid var(--blueColor);
        font-family: Cairo;
        font-size: 1em;
        font-style: normal;
        font-weight: 700;
        line-height: 1em;

    }
    .WritersSearchText {
        font-family: Tajawal;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 500;
        line-height: 1.5em;
        text-align: right;
        color: var(--secondaryHeader);
    }
}

.ArticleHighlited {
    padding: 1.200em;
    background: #FFF3CF;
    border-radius: 5px;
    font-family: Tajawal;
    font-size: 1.429em;
    font-weight: 700;
    line-height: 1.5em;
    text-align: right;
}

@media screen and (max-width: 767px){
    .searchPage {
        .whyMawsuah {
            display: none;
        }
        .SuggestedArticlesListMostReadInterests {
            display: none;
        }
        &Header {
            margin-top: 2.286em;
        }
        &Content {
            .WritersSearchItem {
                .SuggestedArticles {
                    &Icons {
                        width: 10em;
                        height: 10em;
                        object-fit: cover;
                    }
                    
                    &Item {
                        flex-direction: column-reverse;
                        
                        &Content {
                            width: 100%;
                        }   
                    }
                }
            }
            &Interests {
                .bg {
                    width: 23.571em;
                    height: 7.857em;
                }
                .InterestsSearchItem {
                    flex-direction: column;
                    &Numbers {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
.AppHeader {    
    position: fixed;
    top:0;
    width: 100%;
    z-index: 9999 !important;
    background-color: var(--black);
    font-size: 1.071em;
    color: var(--white);
    display: flex;
    justify-content: space-between;
    height: 4em;
    font-family: var(--thirdFontFamily);
    .NotificationsPopUp {
        z-index: 4;
        position: absolute;
        right: -10px;
        top: 23px;
        background-color: var(--white);
        width: 13.571em;
        //height: 11.257em;
        color: var(--black);
        border: 1px solid #F5F5F6;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 12px 12px 12px 0;
        font-family: Cairo;
        font-size: 0.933em;
        font-style: normal;
        font-weight: 700;
        line-height: 2.429em;
        text-align: right;
        img {
            margin-left: 1.286em;
            width: 1.429em;
            height: 1.429em;
        }
        div {
            cursor: pointer;
            height: 40px;
        }
    }
    
    .LoggedIn {
        margin-left: 2.3em;
        line-height: 1.2em;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .userName {
            font-family: Cairo;
            font-size: 1.071em;
            font-style: normal;
            font-weight: 700;
            line-height: 1.200em;
            margin-left: 0.533em;
            margin-right: 1.067em;
        }
        .NotificationIcon {
            position: relative;
            .NotificationCounter {
            
                position: absolute;
                background-color: #D11124;
                border: 1px solid #1A1A1A;
                border-radius: 50%;
                width: 1.417em;
                height: 1.417em;
                font-size: 0.800em;
                line-height: 1em;
                font-weight: 700;
                padding-left: 0.167em;
                padding-top: 0.083em;
                text-align: center;
                top: -0.167em;
                left: 0.750em;
            }
        }
        
    }
    .RegisterButtons {
        margin-left: 2.3em;
        line-height: 1.2em;
        font-weight: 700;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 0.933em;
        .LogIn {
            outline: none;
            width: 6.57em;
            height: 2.7em;
            background-color: var(--black);
            border: 1px solid var(--white);
            margin-left: 1em;
            font-family: var(--thirdFontFamily);
        }
        .Register {
            outline: none;
            width: 6.57em;
            height: 2.7em;
            font-family: var(--thirdFontFamily);
        }
        .StartNow{
            display: none;
            width: 6.57em;
            height: 2.7em;
            margin-left: 2.3em;
            line-height: 1.2em;
            font-weight: 700;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 0.933em;
        }
    }
    .inputSearch {
        .Input {
            margin-bottom: 0;
            
        }
        input {
            border: 0px;
            padding-left: 2.2em;
            background-image: url(../../assets/images/search.svg);
            background-position: 1em 50%;
            background-repeat: no-repeat;
            margin-bottom: 0.5em;
            margin-top: 0.6em;
            line-height: 15px;
            font-weight: 700;
            height: 2.5em;
            width: 31.333em;
            justify-content: center;
            background-color: var(--white);
        }::-webkit-input-placeholder { 
            display: flex;
            padding-top: 0.6em;
            align-items: center;
            font-family: var(--thirdFontFamily);
            color: var(--black);
        }
    }
    
    &Content {
        display: block;
        line-height: 1.2em;
        font-weight: 700;
        display: flex;
        margin: auto 2.1em ;
        p {
            cursor: pointer;
            margin-left: 1em;
        }
        &Menu {
            height: 1.3em;
            margin-right: 1em;
            display: flex;
            justify-content: space-between;
        }
    }
    .border {
        height: 1.6em;
        width: 1px;
        background: rgba(183, 183, 183, 0.2);
        border: none !important;
        margin-left: 1em;
    }
    .Logo {
        margin-left: 0.2em;
        width: 1.4em;
        height: 1.4em;
    }
}

@media screen and (max-width: 600px){
    .AppHeader {
        .LoggedIn{
            .userName{
                display: none;
            }
        }
        .inputSearch {
            display: none;
        }
        .RegisterButtons {
            margin-left: 0;
            .Register {
                display: none;
            }
            .LogIn{
                display: none;
            }
            .StartNow{
                display: block;
            }
            .RequestContent{
                height: 1.3em;
                margin-right: 1em;
            }
        }
        
        &Content {
            margin-right: 1em;
            &Menu {
                display: none;
            }
        }
    }
    
}

@media screen and (max-width: 867px){
    .AppHeader {
        // .inputSearch {
        //     display: none;
        // }
        .RegisterButtons {
            margin-left: 0;
            .Register {
                display: none;
            }
            .LogIn{
                display: none;
            }
            .StartNow{
                display: block;
            }
        }
        
        &Content {
            margin-right: 1em;
            &Menu {
                display: none;
            }
        }
    }
    
}
@media screen and (max-width: 1350px){
    .AppHeader {
        .inputSearch {
            input {
                width: 15em;
            }
         }
    }
}
@media screen and (max-width: 1100px){
    .AppHeader {
        .inputSearch {
            // display: none;
            input {
                width: 15em;
            }
         }
    }
}
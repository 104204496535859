.SharePageDropDown {
    display: flex;
    justify-content: space-between;
    margin-right: 320px;
    margin-top: 41px;
    button {
        width: 212px;
        height: 50px;
    }
    label {
        display: none;
    }
    input {
        height: 47px;
        width: 470px;
    }
}
.ShareSocials {
    display: flex;
    justify-content: space-between;
    &Item {
        width: 147.5px;
        height: 147.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid var(--grayColorMain);
        &:hover {
            border:1px solid #1A1A1A;
            // background-color: var(--blueColor);
            // color: var(--white);
            cursor: pointer;
        } 
        img {
            width: 47px;
            height: 47px;
        }

       
    }
}
.ShareLocation {
    width: 826px;
    height: 310px;
    text-align: right;
    display: flex;
    flex-direction: column;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 33px;
    }
}
.ShareLocationText {
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
}
.ShareForm {
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: right;
    color: #A0A0A0;
    margin-bottom: 70px;
    &Button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 22px;
        color: black;
        line-height: 44px;
        margin-top: 55px;
        button {
            width: 212px;
            height: 47px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 700;
        }
    }
    input {
        background-color: var(--lightGrey);
        border-radius: 4px;
        border: 1px solid var(--rulesBG);
        height: 70px;
        width: 100%;
    }
    textarea {
        border: 1px solid #EDEDED;
        padding: 20px 13px;
        margin-left: 30px;
        outline: none;
        border-radius: 5px;
        -moz-appearance: none;
        resize: none;
        width: 100%;
        height: 297px;
        background-color: var(--lightGrey);
        border-color: #EDEDED;
    }
}
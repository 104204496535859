.AboutPage {
    overflow-x: hidden;
    margin-top: 4.571em;
    .GreenFrame {
        display: flex;
        height: 346px;
        width: 100%;
        &Image {
            min-width: 304px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 15px 15px 0px;
            width: 21.714em;
            height: 24.714em;
            background-color: #0E8A7C;
        }
        &List {
            background-color: rgba(#0E8A7C, 0.12);
            border-radius:  15px 0px  0px 15px;
            min-width: 643px;
            width: 100%;
            h3 {
                margin-top: 1.821em;
                margin-right: 1.821em;
                margin-bottom: 1.5em;
                font-family: Cairo;
                font-size: 2em;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5em;
                text-align: right;
            }
            ul {
                list-style-image: url(../../../assets/images/Dashboard-article/bulletedListDot.svg); 
                margin-right: 2.143em;
               }
            li {
                padding-right: 1.300em;
                margin-bottom: 1em;
                font-family: Tajawal;
                font-size: 1.429em;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5em;
                text-align: right;
            }
        }
    }
    .PartnerWidth {
        width: 100%;
    }
    &ContentColored {
        background: rgba(#9A3F74, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 53px;
        padding-bottom: 101px;
        text-align: right;
        button {
            background-color: transparent;
            outline: none;
            border: 1px solid black;
            border-radius: 4px;
            padding: 10px 12px;
            width: 308px;
            height: 47px;
            font-family: Cairo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0px;
            margin-right: 0px;
            margin-top: 30px;
        }
        h3 {
            font-family: Cairo;
            margin-bottom: 16px;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0px;
                text-align: right;
                width: 1102px; 
        }
        p {
            font-family: Tajawal;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: right;
            color: var(--secondaryHeader);  
            width: 1102px;              
        }
    }
    &ContentWhite {
        display: flex;
        justify-content: center;
        //width: ;
        margin-top: 80px;
        .Content {
            width: 455px;
            h3 {
                font-family: Cairo;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0px;
                text-align: right;
                margin-bottom: 16px;
            }
            p {
                font-family: Tajawal;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0px;
                text-align: right;
                color: var(--secondaryHeader);                
            }
            ul {
                list-style-image: url(../../../assets/images/Dashboard-article/bulletedListDot.svg); 
                padding-right: 1.429em;
            }
            li {
                font-family: Tajawal;
                font-size: 1.429em;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5em;
                text-align: right;
                color: var(--secondaryHeader);
            }
        }
        .image {
            img {
                width: 550px;
                height: 430px;
            }
        }
    }
    &Tabs {
        display: flex;
        padding-right: 164px;
        background-color: #9A3F74;
        color: var(--white);
        .ActiveTab {
            opacity: 1;
            font-weight: 700;
        }
        span {
            cursor: pointer;
            margin-left: 40px;
            padding-top: 26px;
            font-family: Cairo;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0px;
            text-align: right;
            opacity: 0.6;
        }
    }
    &Header {
        display: flex;
        justify-content: center;
        padding: 49px 164px 0 150px;
        background-color: #9A3F74;
        color: white;
        &Content {
            width: 553px;
            font-family: Tajawal;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 33px;
            letter-spacing: 0px;
            text-align: right;

            h1 {
                color: white;
                font-family: Cairo;
                font-size: 52px;
                font-style: normal;
                font-weight: 700;
                line-height: 78px;
                letter-spacing: 0px;
                text-align: right;
                margin-bottom: 50px;
            }   
            h2{
                color: white;
                font-family: Cairo;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0px;
                text-align: right;
                margin-bottom: 30px;
            }
        }
        &Image {
            img {
                margin-top: 55px;
                width: 511px;
                height: 421px;
                object-fit: cover;
            }
            margin-bottom: 175px;
        }
    }
    
}

.ArrowRight {
    cursor: pointer;
 position: absolute;
 top: -25px;
 right: 35px;
 display: flex;
    align-items: center;
    justify-content: flex-end
}
.ArrowLeft {
    cursor: pointer;
    position: absolute;
    top: -25px;
 left: 35px;
 display: flex;
    align-items: center;
}

.SliderContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1102px;
    height: 675px;
    margin: auto;
    margin-left: auto;
    .title {
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
        color: var(--secondaryHeader);
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .subTitle {
        display: none;
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
        color: var(--secondaryHeader);
        margin-bottom: 15px;
    }
    .GroupIcons {
        
        display: none;
    }
    p {
        display: none;
    }
}

.SlickSlide {
    display: flex;
    justify-content: center;
    width: 1200px;
    div {
    display: flex;
    align-items: center;
   // height: 300px;
    }
    .slick-center {
        .subTitle {
            display: block;
            text-align: center;
        }
        .GroupIcons {
            width: 32px !important;
            height: 32px !important;
            border-radius: 0 !important;
            background-color: white !important;
            cursor: pointer;
            display: block;
            margin-bottom:20px;
        }
        .title {
            color: black;
        font-family: Cairo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        }
        
            margin-top: 65px;
            width: 250px !important;
            height: 289px !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center;  
        
        img {
            opacity: 1 !important;
            width: 100% !important;
            height: 100% !important;
            background-color: black !important;
        }
        p {
            display: block;
        }
    }
    .slick-slide {
        div {
            text-align: center;
            display: flex !important;
            justify-content: flex-end;
            flex-direction: column;
            direction: rtl;
        }
        img {
            opacity: 0.4;
            background-color: rgba(13,102,208,0.2);
            border-radius: 15px !important;
            width: 175px;
            height: 199px ;
        }
    }
    
}
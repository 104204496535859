@font-face {
    font-family: 'Cairo';
    font-weight: 500;
    src: url(../assets/fonts/Tajawal-Regular.ttf);
}
@font-face {
    font-family: 'Cairo';
    font-weight: 400;
    src: url(../assets/fonts/Cairo-Regular.ttf);
}
@font-face {
    font-family: 'CairoSub';
    font-weight: 700;
    src: url(../assets/fonts/Cairo-SemiBold.ttf);
}
@font-face {
    font-family: 'Cairo';
    font-weight: 600;
    src: url(../assets/fonts/Cairo-SemiBold.ttf);
}
// @font-face {
//     font-family: 'Cairo';
//     font-weight: 200;
//     src: url(../assets/fonts/Cairo-ExtraLight.ttf);
// }
@font-face {
    font-family: 'Cairo';
    font-weight: 700;
    src: url(../assets/fonts/Cairo-Bold.ttf);
}
@font-face {
    font-family: 'Tajawal';
    font-weight: 700;
    src: url(../assets/fonts/Tajawal-Bold.ttf);
}
@font-face {
    font-family: 'Tajawal';
    font-weight: 500;
    src: url(../assets/fonts/Tajawal-Regular.ttf);
}
// @font-face {
//     font-family: 'Cairo';
//     font-weight: 900;
//     src: url(../assets/fonts/Cairo-Black.ttf);
// }

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url(../assets/fonts/Roboto-Light.ttf);
}
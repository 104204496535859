.WriterWelcomeModal {
    text-align: right;
    .Thanks {
        padding: 2.714em 1.714em 2.286em 1.714em;
        h1 {
            font-size: 1.571em;
            font-weight: 700;
            line-height: 1.5em;
        }
        p {
            font-family: Tajawal;
            font-size: var(--px16);
            font-weight: 500;
            line-height: 1.5em;
        }
    }
    .Header {
        padding: 1.333em 1.278em 1.333em 0;
        border-bottom: 1px solid var(--borderGray);
        font-family: Cairo;
        font-size: var(--px18);
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;
    }
    .Buttons {
        border-radius: 0 0 4px 4px;
        border-top: 1px solid var(--borderGray);
        background-color: var(--white);
        padding: 1.714em 1.714em 1.714em 0 ;
        .Btn {
            line-height: 1em;
            width: 13.929em;
        }
        .BtnColorless {
            line-height: 1em;
        }
        button {
            height: 2.857em;
            margin-left: 1.714em;
            font-size: 1em;
            
        }
        
    }
}
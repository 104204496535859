.mainSignupButtons {
    margin-bottom: 271px;
    .btnHover {
        &:hover {
            background-color: var(--blueColor);
            color: var(--white);
        }
    }
    button {
        width: 100%;
        font-size: 1.286em;
        margin-bottom: 0.889em;
        outline: none;
    }
    .BtnColorless {
        height: 56px;
        color: var(--black);
        border-color: var(--grayColor);
    }
    .RegisterToolTip{
        position: relative;
        display: block;
    }

    .RegisterToolTipHover {
        font-family: Tajawal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5em;
        display: none;
        width: 21.500em;
        height:5.286em;        
        background-color:var(--black);
        color: var(--white);
        text-align: right;
        padding: var(--px16);
        bottom: 140%;
        left: 0;
        border-radius: 4px;
        position: absolute;
        z-index: 40;
        cursor: pointer;
      }
      
      .RegisterToolTip:hover{
        .RegisterToolTipHover {
        visibility: visible;
        display: block;
      }
    
      } 
      .RegisterToolTip:hover::before {
        content: ''; 
        position: absolute;
        left: 4px; top: -29px;
        border: 9.6px solid transparent;
        z-index: 2;
        border-top: 9.6px solid var(--black);
      }
}

.UserInvitesModal {
    text-align: right;
   // width: 768px;
    //height: 485px;
    .buttons {
        background-color: #F5F5F6;
        text-align: right;
        border-radius: 0px 0px 4px 4px;
        padding: 1.714em 2.286em 1.429em 0;
        button {
            margin-left: 1.714em;
            font-family: Cairo;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            line-height: 1em;
            letter-spacing: 0px;
            text-align: center;
            width: 9.286em;
            height: 2.714em;
        }
       
    }
    h1 {
        padding: 1.500em 2em 1.500em 0;
        font-size: var(--px16);
        font-weight: 700;
        line-height: 1.5em;
        color: var(--secondaryHeader);
        border-bottom: 1px solid var(--borderGray);
    }
    &Content {
        border-bottom: 1px solid var(--borderGray);
        button {
            margin-right: 1.714em;
        font-weight: 700;
        width: 7.929em;
        height: 2.714em;
        }
        &Text {
            font-family: Tajawal;
            font-size: var(--px16);
            font-weight: 500;
            line-height: 1.5em;
            padding: 1.500em 1.813em 1.500em 1.813em;
        }
        &Form {
            margin-bottom: 1.071em;
            display: flex;
            padding-left: 2.5em;
            padding-right: 2.5em;
            .col {
                padding: 0 4px 4px;
                align-items: flex-start;
                .Input{
                       
                        margin-bottom: 0;
                        input {
                            border: 0px ;
                            background-color: var(--lightGrey);
                            height: 2.857em;
                        }
                    
                }
                .DropDown {
                    &Label {
                        border: 0px ;
                        height: 2.857em;
                    }
                    label {
                        
                        display: none;
                    }
                }
            }
            
            
        }
    }
}
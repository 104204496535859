.PartnersContent {
    .PartnersBlock {
        img {
            width: 100%;
            height: 17.857em;
            object-fit: cover;
        }
        &Title {
            margin-top: 1.167em;
            margin-bottom: 0.708em;
            display: flex;
            justify-content: space-between;
            font-family: Cairo;
            font-size: 1.714em;
            font-weight: 700;
            line-height: 1.5em;
            .BlockLink {
                font-size: 0.750em;
            }
        }
        &Picture {
            
            &Content {
                margin-left: 2.571em;
                margin-right: 1.714em;
                display: flex;
                margin-top: -6em;
                z-index: 2;
                background-color: var(--white);
                width: 16.071em;
                height: 12.500em;
                border: 1px solid var(--grayColorMain);
                border-radius: 5px;
                align-items: center;
                img {
                    margin-left: auto;
                    margin-right: auto;
                    width: 13.500em;
                    height: 6.071em;
                    object-fit: cover;
                    display: block;
                    width: 100px;
                    height: 100px;
                }
            }
        }
        &Text {
            font-family: Tajawal;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            text-align: right;
        }
    }
    
    
}
.UserProfileAuthEditorItem {
    .approved {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .editingInfo{
            color : var(--black);
            font-size: 14px;
        }
    }
    .reviewingTitle {
        button {
            margin-right: 0.714em;
            width: 9.286em;
            height: 2.714em;
            font-size: 0.875em;
            font-weight: 700;
            line-height: 0.875em;

        }
    }
    
}
.UsersInvites {
    min-height: 100vh;
    text-align: right;
    &Headers {
    border-bottom: 1px solid var(--grayColorMain);
    padding-bottom: 1.714em;
        h1 {
            font-size: 2em;
            font-weight: 700;
            line-height: 1.5em;
        }
        h2 {
            font-size: 1.429em;
            font-weight: 600;
            line-height: 1.5em;
        }
    }
    &Content {
        button {
            font-size: 1em;
            line-height: 1em;
            width: 13.000em;
            height: 2.714em;
        }
        &Table {
            display: flex;
            justify-content: space-between;
            font-family: Tajawal;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 700;
            line-height: 1.5em;
            border-bottom: 1px solid var(--grayColorMain);
            padding-bottom: 7px;
            button {
                width: 9.375em;
                height: 2.375em;
            }
            
        }
        &TableCells {
            display: flex;
            justify-content: space-between;
            font-family: Tajawal;
            border-bottom: 1px solid var(--grayColorMain);
            padding-bottom: 1.429em;
            padding-top: 1.429em;
            color: var(--secondaryHeader);
            font-size: 1em;
            font-weight: 500;
            line-height: 1.714em;
            text-align: right;
            button {
                width: 9.375em;
                height: 2.375em;
            } 
            .name {
                font-weight: 700;
            }
            .icons {
                display: flex;
                align-items: baseline;
                img {
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }:last-child {
                img {
                    display: none;
                }
            }
        }
    }
}
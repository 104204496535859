.UserProfileAuthNoContent {
    display: flex;
    .iconRocket {
        width: 36.143em;
        height: 27.429em;
        object-fit: cover;
    }
    &Items{
        position: relative;
        align-self: center;
        button {
            width: 17.500em;
            height: 3.625em;
            line-height: 0.875em;
        }
        h1 {
            font-family: Cairo;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            text-align: right;
            
        }
        &Text {
            width: 19.500em;
            font-family: Cairo;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 600;
            line-height: 1.5em;
            text-align: right;
            color: var(--secondaryHeader);
            span {
                color: var(--blueColor);
            }
        }
    }
}

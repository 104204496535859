@import "./variables";
@import "./typo";
@import "./fonts";
// ------------------------------------------------
* {
    box-sizing: border-box;
}

html,
body {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    direction: rtl;
    position: relative;
    margin: 0;
    padding: 0;
    background-color: var(--mainBgColor);
    font-family: var(--defaultFontFamily) !important;
    min-height: 100vh;
}
  @media screen and (min-width: 2999px) {
    html, body {
        font-size: 16px;
    }
  }
// ------------------------------------------------
.Flex {
    display: flex;
}

.Grid {
    display: grid;
}

.InlineFlex {
    display: inline-flex;
}

.modalInterests{
    &SubHeader {
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 0;
        color: var(--secondaryHeader);
        span {
            font-weight: 700;
        }
    }
    &Content {
        height: 31.143em;
        overflow-y: scroll;
        &Item{
            width: 11.429em;
            height: 11.429em;
            margin-bottom: 2.143em;
            position: relative;
        }
    }::-webkit-scrollbar {
        width: 0;
    }
    .modalIcon {
        display: flex;
        width: 8.889em;
        height: 8.889em;
        border-radius: 4px;
        cursor: pointer;
        font-family: Cairo;
        font-size: var(--px18);
        font-style: normal;
        font-weight: 700;
        line-height: 1.889em;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: var(--white);
    }
    &Button {
        cursor: pointer;
        font-family: Cairo;
        font-size: 1em;
        font-weight: 700;
        line-height: 1em;
        width: 100%;
        padding-top: 1.214em;
        padding-bottom: 1.214em;
        height: 3.429em;
        text-align: center;
        color: var(--blueColor);
        border: 1px solid var(--blueColor);
        border-radius: 4px;
    }
    h1 {
        font-family: Cairo;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0px;
        margin-bottom: 0;
        text-align: center;
        span {
            margin-right: auto;
            margin-left: auto;
        }
    }
    h3 {
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 0;
        color: var(--secondaryHeader);
        span {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

h1 {
    font-family: var(--defaultFontFamily);
    font-size: 2.286em;
    line-height: 1em;
    //display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
  }

  h2 {
    font-family: var(--defaultFontFamily);
    font-weight: normal;
    font-size: 1.571em;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.563em;
    color: var(--black);
  }
  
  h3 {
    font-family: var(--defaultFontFamily);
    font-weight: normal;
    font-size: 1.571em;
    line-height: 1.455em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.818em;
    color: var(--black);
  }
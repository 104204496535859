.chosenTopics {
    text-align: right;
    cursor: pointer;
    img {
        max-height: 350px;
        object-fit: cover;
    }
    &BigIcon {
        width: 100%;
        border-radius: 0.286em;
    }
    .moreChosenArticles {
        color: var(--blueColor);
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        div {
            margin-left: 0.759em;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 1.286em;
            font-weight: 700;
            line-height: 1.333em;
        }
    }
    
    &Icons {
        width: 11.429em;
        height: 11.429em;
        display: flex;
        img {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &Photo {
        display: flex;
        margin-top: 1.143em;
        .IconAuthorPhoto {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
        p { 
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0.571em;
            span {
                margin-left: 0.286em;
                font-weight: 700;
            }
        }
    }
    &Info {
        display: flex;
        margin-top: 0.857em;
        p { 
            margin-top: auto;
            margin-bottom: auto;
            span {
                
                font-weight: 700;
            }
        }
    }
    &Item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.286em;
        padding-bottom: 2.286em;
        border-bottom: 1px solid var(--borderGray);
    }
    &Icon {
        width: 38.5em;
        height: 25em;
        margin-bottom: 1em;
        
        background-color: var(--gray);
    }
    h1 {
        margin-left: 1.722em;
        text-align: right;
        font-size: 1.286em;
        font-weight: 700;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.25em;
    }
    h2 {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-bottom: 3px;
        padding-top: 3px;
        line-height: 25px;
        margin-left: 7.813em;
        text-align: right;
        font-size: 1.143em;
        font-weight: 500;
        color: var(--secondaryHeader);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

.iconGrayCircle{
    margin-left: 0.666em;
    margin-right: 0.666em;
}

@media screen and (max-width: 767px) {
    .chosenTopics {
        
      
        &BigIcon {
            width: 100%;
        }
        h1, h2 {
            margin-left: 0;
            
        }
        &Icons {
            width: 5.357em;
            height: 5.357em;
        }
        &Info {
            width: 130%;
        }
    }
    .mobileChosenTopics {
        border-top: 1px solid  var(--borderGray);
        padding-top: 2.571em;
        margin-top: 2.286em;
    }
}
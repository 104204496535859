.ResetPassword {
    display: flex;
  
    flex-direction: column;
    align-items: center;
    .AccountSettingsForm {
        width: 50%;
        border-bottom: 1px solid #EDEDED;
    }
  
    &Button {
        button {
            outline: none;
            font-size: 1em;
            width: 9.286em;
            height: 2.857em;
        }
    }
}
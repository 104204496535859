.RecommendedInterests {
    h3 {
        font-weight: 700;
    }
    &Header {
        span {
            cursor: pointer;
        }
    }
    .moreRecommendedInterests {
        color: var(--blueColor);
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        p {
            
            margin-left: 0.591em;
            margin-top: auto;
            margin-bottom: auto;
            font-size: var(--px18);
            font-weight: 700;
            line-height: 1.333em;
        }
    }
    &Content{
        margin-top: 2.5em;
        margin-bottom: 2.5em;
        .bg {
            border-radius: 4px;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            width: 25.000em;
            height: 12.857em;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    .smallLine {
        margin-bottom: 0;
    }
    &Item {
        margin-right: auto;
        margin-left: auto;
        text-align: right;
        width: 25.000em;
        .interest {
            margin-top: 0.889em;
            font-weight: 700;
            font-size: var(--px18);
            line-height: 1.8em;
        }
        .numbers {
            color: var(--secondaryHeader);
            font-weight: 400;
            line-height: 1.5em;
            span {
                font-weight: 700;
            }
        }
    }
   
}

@media screen and (max-width: 767px){
    .RecommendedInterests {
        .bg {
            width: 23.571em;
        }
    }
}
.AppFooter {
    background-color: var(--black);
    text-align: right;
    .AppFooterContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 3.357em;
        margin-bottom: 1.429em;
    }
    .smallText {
        padding-bottom: 3.250em;
        margin-right: 193px;
        font-family: Tajawal;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 500;
        line-height: 1.5em;
        color: var(--secondaryHeader);
    }
    .staticPages {
        display: flex;
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;
        color: var(--secondaryHeader);
        border-bottom: 1px solid rgba(183, 183, 183, 0.2);
        p{
            cursor: pointer;
            border-right: 1px solid rgba(183, 183, 183, 0.2);
            padding-left: 1.214em;
            padding-right: 1.214em;
            height: 24px;
            margin-bottom: 20px;
            &:hover {
                color: var(--white);
            }
            &:first-child {
                border-right: 0px;
                padding-right: 0;
            }
            &:last-child {
                padding-left: 0;
            }
        }
    }

    .Icons {
        height: 1.714em;
        display: flex;
        align-items: center;
        div {
            cursor: pointer;
        }
        img{
            margin-bottom: 0.2em;
            margin-right: 1.5em;
            margin-left: 1.5em; 
        }   
    }
    .Flex {
        .Icons {
            height: 1.714em;
            display: flex;
            align-items: center;
            div {
                cursor: pointer;
            }
            img{
                margin-bottom: 0.2em;
                margin-right: 1.5em;
                margin-left: 1.5em; 
            }   
        }
        justify-content: space-between;    
    }
    .Logo {
        margin-left: 0.225em;
        cursor: pointer;
        text-align: center;
        img {
            padding-bottom:1.786em;
        }
    }
    .CookiesBar{
        position: fixed;
        overflow: hidden;
        bottom: 0%;
        background: #626262;
        width: 100%;
        height: 80px;
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 14px;
        /* identical to box height, or 93% */
        text-align: center;
        color: #FFFFFF;
        padding:20px 0 20px 0;
        z-index: 9999 !important;
        p .Btn{
            margin-right: 10px;
            box-sizing: border-box;
            align-items: center;
            padding: 12px 10px;
            border: 1px solid #fff;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            text-decoration: none;
            background: #626262;
            width: auto;
            height: auto;        
        }
        p span{
            text-decoration: underline;
            cursor: pointer;
        }
    }
}


@media screen and (max-width: 867px){
    .AppFooter {
        width: 100%;
        text-align: right;
        min-height: 300px;
        // .Flex {
            // display: block;
            // display: none;
            .staticPages {
                display: none;
                text-align: right;
                // display: grid;
                grid-template-columns: 3fr 3fr;
                p {
                    margin-left: 0;
                }
            }
        // }
    }
}
.ArticleReview {
    .Input {
        background-color: #F5F5F6;
        input {
        height: 48px;
        font-family: Tajawal;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: right;
        border: 0;
        }
    } 
    .textToSelect {
        .HighlitedText {
            background-color: rgba(255, 51, 102, 0.25 ) ;
            position: relative;
            user-select: none;
        }
    } ::selection {
        background: rgba(255, 51, 102, 0.25 ) !important;
    }
}

.CommentPopUpHighlights {
    width: 200px;
    position: absolute;
    word-wrap: break-word;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    z-index: 20;
    border: 2px solid rgba(255, 51, 102, 1);
    background-color: #F5F5F6;
    border-radius: 4px;
    top: 26px;
    left: -75px;
    white-space: break-spaces;
}

.SelectedTextComment {
    display: flex;
    width: 25.571em;
    height: 10.929em;
    button {
        width: 5.714em;
        height: 2.714em;
        font-weight: 700;
        font-size: 1em;
        line-height: 1em;
        margin: var(--px16) 0 0 var(--px16);
        outline: none;
    }
    textarea {
        color: var(--secondaryHeader);
        font-family: Tajawal;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: right;
        padding: 8px;
        outline: none;
        text-decoration: none;
        border: 1px solid #E8E8E8;
        background-color: #F6F6F6;
        border-radius: 5px;
        width: 238px;
        height: 122px;
        margin: 16px 16px 16px 8px;
        resize: none;
    }
}


.ArticleReviewOptions {
    button {
        width: 207px;
        height: 38px;
        border-radius: 4px;
        font-family: Cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
    }
    .ButtonReject {
        background-color: var(--alert);
    }
    .ButtonApprove {
            background-color: var(--success);
    }
}
.ReviewNumbers {
    display: flex;
    justify-content: center;
    margin-bottom: 1.786em;
    img {
        margin-left: 0.357em;
        margin-right: 0.357em;
    }
}
.HighlitedTextArticleReview {
    user-select: none;
    background-color: rgba(255, 51, 102, 0.25 );
    position: relative;
    mark {
        background-color: transparent;
    }
}
.HighlitedTextArticleReviewPopUp {
    border: 1px solid #CACACA;
    border-radius: 4px;
    z-index: 10;
    cursor: pointer;
    position: relative;
    width: 140px;
    height: 38px;
    background-color: var(--blueColor);
    color: white;
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 4px;
    padding-top: 10px;
}
.ReviewArticleTimer {
    font-family: Cairo;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.714em;
    color: var(--alert);
}
.ReviewerComment {
    position: absolute;
    top: 0;
    right: -70px;
    display: flex;
    &Amount {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.714em;
        width: 1.786em;
        height: 1.714em;
        padding: 0 0.571em;
        margin-right: 0.357em;
        background: var(--lightGrey);
        border-radius: 0.143em;
        color: var(--selectionColorReviewPage);
    }
}
.ReviewerCommentsText {
    z-index: 3;
    min-width: 300px;
    padding: 10px;
    display: flex;
    font-size: 16px;
    font-family: Tajawal;
    flex-direction: column;
    background-color: var(--white);
    border: 2px solid var(--selectionColorReviewPage);
    border-radius: 4px;
}

.PortalReviewHighlight {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--blueColor);
    color: white;
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    img {
        width: 30px;
        height: 30px;
    }
    .text {
        color: white;
        font-family: Cairo;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        margin-left: 1em;
    }
    .highlightBorder {
        margin-left: var(--px16);
        width: 0.143em;
        height: 1.714em;
        background-color: var(--secondaryHeader);
    }
}
mark { 
    padding: 0;
}

.SelectedTitle {
    user-select: text;
    font-family: Cairo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: right;
}

   
.AccountSettingsForm {
    text-align: right;
    padding: 34px 30px 30px 30px;
    border-right: 1px solid var(--borderGray);
    border-left: 1px solid var(--borderGray);
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5em;
    .silverInput {
        
        input {
            background-color: var(--lightGrey);
        }
        .Images {
            margin-left: 0.571em;
            background-color: black;
            border-radius: 50%;
           width: 24px;
           height: 24px;
           display: flex;
            img {
                align-self: center;
                margin-right: auto;
                margin-left: auto;
                width: 12px;
                height: 12px;
            }
        }
        
    }
    .Input {
        input{
            border: 1px solid #EDEDED;
        }
        
    }
    .ter {
        div {
            align-self: start;
        }
    }
}  
.AccountSettingsButton {
    button {
        font-size: 1em;
        width: 9.286em;
        height: 2.857em;
    }
    border: 1px solid var(--borderGray);
    border-radius: 0px 0px 5px 5px;
    text-align: right;
    padding: 30px;
}
.SessionLog {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--borderGray);
    border-top: 0px;
    padding: 30px 30px 12px 34px;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5em;
    text-align: right;
    .edit {
        color: var(--blueColor);
        font-weight: 700;
    }
    .text {
        color: var(--secondaryHeader);
    }
}
.SessionLogBtn {
    border-top: 0px !important;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;
    text-align: center;
    color: var(--blueColor);
}
.DeleteAccount {
    font-size: var(--px18);
    font-weight: 700;
    line-height: 1.5em;
    text-align: right;
    padding: 1.333em 1.667em 1.333em 0px;
    border: 1px solid var(--borderGray);
    border-top: 0px;
    border-radius: 5px;
}
.ContentPreferences {
    border-bottom: 1px solid var(--borderGray);
    
        font-size: var(--px16);
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;

   
    .CheckBox{ 
        label {
            font-weight: 600;
        }
    }
}
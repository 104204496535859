.ResetPasswordEmailInput {
    .Input {
        margin-right: 15px;
        margin-left: 15px;
        label {
            font-weight: 600;
            line-height: 1.5em;
            margin-bottom: 0.571em;
            img {
                margin-right: 0.2em;
                margin-bottom: 0.7em;
            }
        }
        input {
            margin-bottom: 5px;
            background-color: var(--lightGrey);
            border-color: #EDEDED;
        }::placeholder {
            padding-right: 1em;
            color: #A0A0A0;
        }
    }
}
.ResetPasswordErrorMessage {
    font-family: Cairo;
    font-size: var(--px12);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
    text-align: right;
    color: var(--alert);
    margin-right: 15px;
    margin-bottom: 25px;
}
.ResetPasswordSubTitle {
    font-family: Cairo;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 33px;
letter-spacing: 0px;
text-align: right;

}
.UserProfileAuthWriter {
    min-height: 60vh;
    a {
        color: var(--secondaryHeader);
        font-family: Cairo;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 600;
        line-height: 1.375em;
        letter-spacing: 0px;
        text-align: right;


    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: var(--black);
        background-color: #fff;
        border-color: white; 
        border-bottom: 2px solid var(--black);
        border-radius: 0px;
        font-weight: 700;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
         //border-color:white; 
         //border-bottom: 0px solid black;
    }
    .nav-tabs .nav-item {
        margin-bottom: 0px;
    }
    .approved {
        font-size: var(--px16);
        font-weight: 700;
        line-height: 1.5em;
        color: var(--success);
        img {
            margin-left:0.859em;
            margin-right: 0.484em;
        }
        button {
            font-size: 0.875em;
            width: 9.286em;
            height: 2.714em;
        }
    }
    .reviewing {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        &Title {
            color: var(--orangeColor);
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            img {
                margin-left: 0.464em;
            }
            button {
                margin-right: 0.714em;
                font-size: 14px;
                line-height: 1em;
                width: 9.286em;
                height: 2.714em;
                border-radius: 0.286em;
            }
        }
        &Time{ 
            font-size: 1em;
            font-weight: 600;
            line-height: 2.714em;
            span {
                font-weight: 700;
            }
        }
    }
    .rejected {
        &Title {
            
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            color: var(--alert);
            img {
                margin-left: 0.464em;
            }
            button {
                margin-right: 0.625em;
                font-size: 0.875em;
                width: 9.286em;
                height: 2.714em;
            }
        }
        &Info {
            
            h3 {
                font-family: Cairo;
                font-size: var(--px16);
                font-weight: 700;
                line-height: 1.5em;
                color: var(--secondaryHeader);
            }
            color: var(--secondaryHeader);
            font-family: Tajawal;
            font-size: var(--px16);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5em;
            padding: 1em 1.5em 1em 3.125em;
            background-color: rgba(208, 13, 13, 0.12);
            border-radius: 4px;
        }
    }
    .editing {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        &Title {
            font-size: var(--px16);
            font-weight: 700;
            line-height: 1.5em;
            img {
                margin-left:0.375em;
                margin-right: 0.484em;
            }
            button {
                margin-right: 0.484em;
                font-size: 0.875em;
                width: 9.286em;
                height: 2.714em;
            }
        }
        &Info {
            font-size: 1em;
            font-weight: 600;
            line-height: 2.714em;
            span {
                font-weight: 700;
            }
        }
    }
    .draft {

    }
}
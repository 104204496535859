.interestsTabsHeader{
        display: flex;
        justify-content: space-between;
        margin-top:4.571em;
        h1 {
            margin-left: 0.25em;
            font-family: Cairo;
            font-size: 1.714em;
            font-weight: 700;
            line-height: 1.750em;
            text-align: right;
            margin-bottom: 0;
        }
        img {
            margin-left: 1.5em;
            margin-top: 0.7em;
            width: 25px;
            height: 25px;
        }
        &Numbers {
            display: flex;
            font-family: Cairo;
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: 1.714em;
            text-align: right;
            color: var(--secondaryHeader);
            margin-top: 0.714em;
            span {
                margin-left: 0.571em;
                font-weight: 700;
            }
            .border {
                margin-top: 0.25em;
                height: var(--px16);
                margin-left: var(--px16);
                margin-right: var(--px16);
            }

        }
}
.interestsTabsMenu {
    margin-top: 4.571em;
    &Content {
        font-size: 1em;
        font-weight: 700;
        line-height: 2.429em;
        text-align: right;
        border-bottom: 1px solid var(--borderGray);
        width: 18.214em;
        div {
            cursor: pointer;
            display: flex;
            img {
                align-self: center;
                width: 1.714em;
                height: 1.714em;
                margin-left: var(--px18);
            }
        }
    }
    button {
        font-size: 1em;
        line-height: 1em;
        width: 18.214em;
    }
}

@media screen and (max-width: 767px){
    .interestsTabsHeader{
        flex-direction: column;
    }
}

.UserSettings {
    overflow-x: hidden;
    margin-top: 4.571em;
    .fileUploadInput {
        cursor: pointer;
        border: 1px solid var(--blueColor);
        width: 136px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--blueColor);
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
    }
    .UserNotificationsTitle {
        font-size: var(--px18);
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;
        
    }
    .UserNotifications {
        cursor: pointer;
        border-radius: 5px 5px 0px 0px;
            border: 1px solid #EDEDED;
            border-bottom: none;
        &Item {
            border-bottom: 1px solid #EDEDED;
            padding: 24px 30px 24px 36px;
            display: flex;
            justify-content: space-between;
            font-family: Cairo;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: right;
        }
        &Switch {
            height: 5.214em;
            padding: 1.714em 2.143em 1.643em 0px;
            text-align: right;
            background-color: var(--accordionColor);
            border-bottom: 1px solid #EDEDED;
            display: flex;
            font-size: var(--px12);
            font-weight: 600;
            line-height: 1.5em;
        }
        &Checkbox {
            .CheckBox {
                input[type=checkbox]{
                    
                }:checked {
                    background-color: var(--blueColor);
                }
                
            }
            height: 7.929em;
            padding: 1.714em 2.143em 1.643em 0px;
            background-color: var(--accordionColor);
            border-bottom: 1px solid #EDEDED;
        }
    }
    
    &Item {
        border: 1px solid #EDEDED;
        border-radius: 5px 5px 0px 0px;
        .Flex {
            margin: 0.714em 1.296em 0.714em 2.429em;
            justify-content: space-between;
            .Flex1 {
                display: flex;
            }
            &Edit {
                cursor: pointer;
                align-self: center;
                color: var(--blueColor);
                font-weight: 700;
                line-height: 1.5em;
            }
            &Icon {
                align-self: center;
            }
            &Text {
                text-align: right;
                margin-right: 1.286em;
                .Title {
                    font-size: var(--px16);
                    font-weight: 700;
                    line-height: 1.5em;
                }
                .SubTitle {
                    font-weight: 400;
                    line-height: 1.5em;
                }
            }
        }
    }
    &Menu {
        width: 12.643em;
        &Item {
            margin-bottom: 11px;
            cursor: pointer;
            color: var(--secondaryHeader);
            font-family: Cairo;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            line-height: 2.429em;
            letter-spacing: 0px;
            text-align: right;
            img {
                width: 1.286em;
                height: 1.286em;
                margin-left: 1.357em;
            }
        }
    }
    &Title {
        font-family: Cairo;
        font-size: 2.286em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        text-align: right;
    }
    &SubTitle{
        font-family: Cairo;
        font-size: var(--px16);
        font-style: normal;
        font-weight: 600;
        line-height: 1.5em;
        text-align: right;
    }
    &Profile {
        border: 1px solid  #EDEDED;
        border-radius: 2px;
        .btnProfile {
            margin-right: 2em;
        }
        .Bio {
            &Title {
                font-size: 1em;
                font-weight: 600;
                line-height: 1.5em;
                margin-left: 2.143em;
                display: flex;
                justify-content: space-between;
                .numbers {
                    font-family: Tajawal;
                    font-size: 0.857em;
                    font-weight: 500;
                    line-height: 1.5em;
                }
            }
            margin-right: 2.143em;
            textarea {
                border: 1px solid #EDEDED;
                padding: 20px 13px;
                margin-left: 30px;
                outline: none;
                border-radius: 5px;
                -moz-appearance: none;
                resize: none;
                width: 47.857em;
                height: 6.286em;
                background-color: var(--lightGrey);
                border-color: #EDEDED;
            }
        }
        &Header {
            font-family: Cairo;
            font-size: var(--px18);
            font-style: normal;
            font-weight: 700;
            line-height: 1.5em;
            text-align: right;
            border-bottom: 1px solid #EDEDED;
            padding: 1.333em 1.667em 1.333em 0;
        }
        &Content {
            text-align: right;
            .EditProfilePhoto {
                display: flex;
                margin-right: 2.143em;
                margin-top: 2.429em;
                img {
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
                .text {
                    margin-right: 2.143em;
                    align-self: center;
                    font-size: 1em;
                    font-weight: 600;
                    line-height: 1.5em;
                    button {
                        margin-bottom: 0.714em;
                        width: 9.714em;
                        height: 2.857em;
                    }
                }
            }
            .EditProfileData {
                margin-right: 2.143em;
                .inlineFlex {
                    display: inline-flex;
                    font-size: 14px;
                    .Input {
                        margin-left: 2.143em;
                    }
                }
                .DropDown {
                    width: 22.857em;
                }
                .Input {
                    width: 22.857em;
                    label {
                        font-weight: 600;
                        line-height: 1.5em;
                        img {
                            margin-right: 0.2em;
                            margin-bottom: 0.7em;
                        }
                        .exclamation {
                            margin-bottom: 0em;
                        }
                    }
                    input {
                        height: 2.714em;
                        background-color: var(--lightGrey);
                        border-color: #EDEDED;
                    }::-webkit-input-placeholder {
                       padding-top: 3px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1200px){
    .UserSettings {
        &Profile {
            .Bio {
                margin-left: 2.143em;
                &Title {
                    margin-left: 0;
                }
                textArea {
                    width: 100%;
                }
            }
            &Content {
                .EditProfilePhoto {
                    display: block;
                    .text {
                        margin-right: 0;
                        margin-top: 2.143em;
                    }
                }
                .EditProfileData {
                    display: block;
                    margin-left: 2.143em;
                    .DropDown {
                        width: 100%;
                    }
                    .inlineFlex {
                        display: block;
                        .Input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 3px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider1 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(13, 102, 208, 0.24);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider1:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    top: -4px;
    left: -4px;
    bottom: 0px;
    background-color: var(--blueColor);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  
  
  input:checked + .slider1:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  
  /* Rounded sliders */
  .slider1.round {
    border-radius: 34px;
  }
  
  .slider1.round:before {
    border-radius: 50%;
  }
.DropDown {
    position: relative;
    label {
        width: 100%;
        font-weight: 600;
        line-height: 1.5em;
        font-family: Cairo;
        .lastWordBold {
            font-weight: 700;
            color: var(--black);
        }
        img {
            margin-bottom: 0.7em;
            margin-left: 0.286em;
            margin-right: 0.286em;
        } 
        span {
            font-family: Cairo;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.5em;
            color: var(--secondaryHeader);
        }
    }
    &Label {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1em;
        line-height: 1.5em;
        min-height: 2.4em;
        background-color: var(--lightGrey);
        border-radius: 4px;
        border: 1px solid #EDEDED;
        color: var(--silverColor);
        cursor: pointer;
        span {
            padding-right: 1em;
        }
        img {
            padding-left: 0.744em;
            position: absolute;
            left: 0;
        }
        &MultipleInput {
            font-family: Cairo;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            background-color: var(--white);
            padding-right: 0.643em;
            margin-bottom: 0.143em;
            margin-top: 0.143em;
            min-width: 5.714em;
            font-size: 1em;
            line-height: 1.5em;
            border: 1px solid var(--grayColorMain);
            border-radius: 5px;
            outline:none;
            cursor: pointer;
            color: var(--black);
            margin-right: 0.286em;
            .IconDeleteItemDropDown {
                img {
                    margin-right: 0.571em;
                    position: inherit;
                }
               
            }
        }
        &Input {
            width: 100%;
            font-size: 1em;
            line-height: 1.5em;
            outline:none;
            cursor: pointer;
            border: none;
            background-color: var(--lightGrey);
            padding-right: 1em;
            color: var(--silverColor);
            
        }::-webkit-input-placeholder {
            padding-top: 3px;
         }
    }
    &Options {
        display: none;
        position: absolute;
        z-index: 2;
        // top: 35px;
        left: 0;
        right: 0;
        background: var(--white);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.24);
        &.active {
            display: block;
            max-height: 13.500em;
            overflow-y: auto;
        }
        &Link {
            border: none;
            width: 100%;
            font-weight: 400;
            padding: 0.714em 2.857em 0.714em 0.714em;
            border-radius: 3px;
            font-size: 1em;
            line-height: 1.5em;
            cursor: pointer !important;
            &:hover {
                background-color: var(--lightGrey);
            }
        }
    }
}

